<div class="popup-wrap" [class.active]="active" (click)="onClick($event)">
    <div class="popup" id="popup-{{unique}}">
        <div class="popup-head"><div class="smallbtn close" (click)="onClose()"></div></div>
        <div class="popup-title">{{words['credit-order']?.['title']?.[lang.slug]}}</div>
        <div class="popup-content compact">
            <form class="gf" (submit)="onSubmit()">
                <div class="gf-row b-margined-20">
                    <div class="gf-inputwrap"><input type="text" name="name" [(ngModel)]="name" [class.error]="errors['name']" [placeholder]="words['credit-order']?.['name']?.[lang.slug]"></div>
                    <div *ngIf="errors['name']" class="gf-error">{{words['errors']?.[errors['name']]?.[lang.slug]}}</div>
                </div>
                <div class="gf-row b-margined-20">
                    <div class="gf-inputwrap"><input type="text" name="phone" [(ngModel)]="phone" (keyup)="removeNonDigit('phone')" [class.error]="errors['phone']" [placeholder]="words['credit-order']?.['phone']?.[lang.slug]"></div>
                    <div *ngIf="errors['phone']" class="gf-error">{{words['errors']?.[errors['phone']]?.[lang.slug]}}</div>
                </div>
                <div class="gf-row b-margined-20">
                    <div class="gf-inputwrap"><input type="email" name="email" [(ngModel)]="email" [class.error]="errors['email']" [placeholder]="words['credit-order']?.['email']?.[lang.slug]"></div>
                    <div *ngIf="errors['email']" class="gf-error">{{words['errors']?.[errors['email']]?.[lang.slug]}}</div>
                </div>
                <div class="gf-row b-margined-20">
                    <div class="gf-inputwrap"><input type="text" name="citizenship" [(ngModel)]="citizenship" [class.error]="errors['citizenship']"  [placeholder]="words['credit-order']?.['citizenship']?.[lang.slug]"></div>
                    <div *ngIf="errors['citizenship']" class="gf-error">{{words['errors']?.[errors['citizenship']]?.[lang.slug]}}</div>
                </div>
                <div class="gf-row b-margined-20">
                    <div class="gf-inputwrap"><input type="text" onfocus="(this.type='date')" name="year" [(ngModel)]="year" [class.error]="errors['year']" [placeholder]="words['credit-order']?.['year']?.[lang.slug]"></div>
                    <div *ngIf="errors['year']" class="gf-error">{{words['errors']?.[errors['year']]?.[lang.slug]}}</div>
                </div>
                <div class="gf-row b-margined-20">
                    <div class="gf-inputwrap"><input type="text" name="criminal" [(ngModel)]="criminal" [class.error]="errors['required']" [placeholder]="words['credit-order']?.['criminal']?.[lang.slug]"></div>
                    <div *ngIf="errors['required']" class="gf-error">{{words['errors']?.[errors['required']]?.[lang.slug]}}</div>
                </div>
                <div class="gf-row b-margined-30">
                    <div class="gf-inputwrap"><input type="text" name="credits" [(ngModel)]="credits" [class.error]="errors['required']" [placeholder]="words['credit-order']?.['credits']?.[lang.slug]"></div>
                    <div *ngIf="errors['required']" class="gf-error">{{words['errors']?.[errors['required']]?.[lang.slug]}}</div>
                </div>
                <div class="gf-row b-margined-30">
                    <div class="gf-inputwrap"><input type="number" name="usd" [(ngModel)]="usd" [class.error]="errors['required']" [placeholder]="words['credit-order']?.['usd']?.[lang.slug]"></div>
                    <div *ngIf="errors['required']" class="gf-error">{{words['errors']?.[errors['required']]?.[lang.slug]}}</div>
                </div>
                <div class="gf-row b-margined-30">
                    <div class="gf-inputwrap"><input type="number" name="youwant" [(ngModel)]="youwant" [class.error]="errors['required']" [placeholder]="words['credit-order']?.['youwant']?.[lang.slug]"></div>
                    <div *ngIf="errors['required']" class="gf-error">{{words['errors']?.[errors['required']]?.[lang.slug]}}</div>
                </div>
                <div class="gf-row b-margined-30">
                    <div class="gf-btnwrap"><button type="submit" class="btn" [class.busy]="loading"><span>{{words['register']?.['register']?.[lang.slug]}}</span></button></div>
                    <div *ngIf="errorExists" class="gf-error ta-center">{{words['credit-order']?.['send-request']?.[lang.slug]}}</div>
                </div>
            </form>
        </div>
    </div>
</div>
