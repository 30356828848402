<div class="module module--assets module--space-small module--bg-image" data-name="assets" id="">
  <div class="module__bg-image module__bg-image --position-buttom">
    <div class="module__bg-image__img --tablet" style="--src:url('sites/2/2023/06/yuh-bg-desktop-purple-03.png')">
    </div>
    <div class="module__bg-image__img " style="--src:url('sites/2/2023/06/yuh-bg-mobile-purple-05.png')"></div>
  </div>
  <div class="assets" data-component='{"component": "assets"}' data-settings='{ "apiUrl": "" }'>
    <div class="container assets__inner">
      <h2 class="h2 assets__headline" [innerHTML]="words['invest']?.['assets-title']?.[lang.slug]"><br /></h2>

      <div class="assets__main">
        <div class="assets__main__tabs js-assets__main__tabs" data-component='{ "component": "slider" }'
          data-slider-settings='{ "spaceBetween": 8, "centerInsufficientSlides": false, "breakpoints": { "tablet": { "spaceBetween": 12 }} }'>
          <div class="assets__main__tabs__tab js-assets__main__tabs__tab label" data-source="cryptocurrencies"
            data-ga4-trigger-params='{"event":"explore_assets","filter":"Cryptocurrencies"}'
            data-ga4-trigger-params='{"event":"explore_assets","filter":"Cryptocurrencies"}'>
            <span>{{words['invest']?.['cryptocurrencies']?.[lang.slug]}}</span>
          </div>
        </div>

        <div class="assets__main__widgets js-assets__main__widgets">
          <div class="assets__main__widgets__item">
            <!-- TradingView Widget BEGIN -->
            <div class="tradingview-widget-container" id="tv-container">
              <div class="tradingview-widget-container__widget"></div>
            </div>
            <!-- TradingView Widget END -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
