<div class="module module--hero_app" data-name="hero_app" id="">
  <div class="hero-app  " data-component='{"component": "hero-app"}'>
    <div class="hero-base hero-base--2 hero-base--purple"></div>
    <div class="hero-base"></div>
    <div class="hero-general__back">
      <picture class="">
        <source data-srcset="https://www.yuh.com/en/wp-content/uploads/sites/2/2023/02/background-1-desktop.svg"
          media="--tablet" />
        <img class="lazyload hero-app__bg-illustration" data-sizes="auto"
          data-srcset="https://www.yuh.com/en/wp-content/uploads/sites/2/fly-images/1700/background-1-2096x9999.png"
          style="" />
      </picture>
    </div>
    <div class="hero-general__front">
      <div class="container hero-app__container">
        <div class="hero-app__content">
          <h1 class="h1 hero-app__content__headline" [innerHTML]="words['invest']?.['hero-title']?.[lang.slug]"><br />
          </h1>
          <div class="hero-app__content__description copy-lg">{{words['invest']?.['hero-text']?.[lang.slug]}}<br />
          </div>
          <a class="button button--style--link button--size--default hero-app__content__cta js-hero-app__content__cta"
            data-link="Benefits" data-ga4-trigger-params='{"event":"appservices_header","link_to":"en\/app\/invest"}'>
            <div class="button__inner">
              <div class="icon button__icon button__icon--right"><svg xmlns="http://www.w3.org/2000/svg" width="24"
                  height="24" viewBox="0 0 24 24">
                  <path
                    d="M20.383 12.578a1.5 1.5 0 0 0-.323-1.64l-7-7a1.5 1.5 0 0 0-2.12 2.122l4.44 4.44H5a1.5 1.5 0 0 0 0 3h10.38l-4.44 4.44a1.5 1.5 0 0 0 2.084 2.16l.037-.037 7-7a1.5 1.5 0 0 0 .322-.485Z"
                    fill="currentColor" />
                </svg></div> <span class="button__label">{{words['invest']?.['discover-benefits']?.[lang.slug]}}</span>
            </div>
          </a>
        </div>
        <div class="hero-app__illustrations hero-app__illustrations--position-bottom">
          <div class="hero-app__illustrations__bg">
          </div>
          <div class="hero-app__illustrations__inner">

            <div class="image-animation hero-app__illustration hero-app__illustration--json">
              <!-- data-component='{"component": "image-animation"}' -->
              <!-- data-json="wp-content/uploads/sites/2/2023/06/invest-1200x1200-1.json"> -->

              <div style="margin-left: 10rem" class="image-animation__img js-image-animation__img"><img src="assets/images/mob.png"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
