import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AppService } from "src/app/common/services/app.service";
import { AuthService } from "src/app/common/services/auth.service";
import { ILang } from "src/app/model/entities/lang.interface";
import { ISettings } from "src/app/model/entities/settings.interface";
import { IWords } from "src/app/model/entities/words.interface";
import tvOptions from '../../tv-options-crypto';

@Component({
    selector: "the-assets",
    templateUrl: "assets.component.html",
    styleUrls: ["assets.component.scss"],
})
export class AssetsComponent implements OnInit {
    constructor(
        protected appService: AppService,
        protected authService: AuthService,
        protected router: Router,
    ) {}

    get words(): IWords {return this.appService.words;}      
    get lang(): ILang {return this.appService.lang;}  
	  get settings(): ISettings {return this.appService.settings;}
	  // get tvContainerEl(): HTMLElement {return this.tvContainerRef.nativeElement;}

	  // auth
    set pRegisterActive(v: boolean) {this.appService.pRegisterActive = v;}

    public ngOnInit(): void {
        const tvContainer: HTMLElement = document.getElementById('tv-container');
        let node = document.createElement('script');
        node.src = 'https://s3.tradingview.com/external-embedding/embed-widget-market-quotes.js';
        node.type = 'text/javascript';
        node.async = true;
        node.innerText = JSON.stringify(tvOptions);
        tvContainer.appendChild(node);
    }
}
