<div class="module module--image-text module--space-top-small module--space-bottom-medium module--bg-image"
  data-name="image-text" id="">
  <div class="module__bg-image module__bg-image">
    <div class="module__bg-image__img " style="--src:url('uploads/sites/2/2023/06/yuh-bg-mobile-purple-03.png')">
    </div>
  </div>

  <div class="image-text container ">
    <picture class="image-text__image">
      <img class="lazyload " data-sizes="auto"
        data-srcset="https://www.yuh.com/en/wp-content/uploads/sites/2/2023/03/sq-x-post.svg 280w"
        alt="Post Finance Parachutes" style="" />
    </picture>
    <div class="image-text__body image-text__body__center">
      <h2 class="h2 image-text__headline weight-extrabold" [innerHTML]="words['about']?.['get-alpha']?.[lang.slug]"></h2>
      <p class="image-text__description copy-lg">{{words['about']?.['image-text-description']?.[lang.slug]}}<br />
      </p>
    </div>
  </div>
</div>
