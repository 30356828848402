<div class="wrapper">
    <div class="converter">
        <div class="c-tabs">
            <button (click)="changeCurrency(0)" class="c-tab" [class.c-active]="activeTab === 0"><span>{{words['invest']?.['converter-ltc']?.[lang.slug]}}</span></button>
            <button (click)="changeCurrency(1)" class="c-tab" [class.c-active]="activeTab === 1"><span>{{words['invest']?.['converter-btc']?.[lang.slug]}}</span></button>
        </div>
        <div class="c-inputs">
            <div class="c-input">
                <div class="input-title">{{words['invest']?.['give']?.[lang.slug]}}</div>
                <div class="input-body">
                    <input type="number" name="value" [ngModel]="usdValue" (ngModelChange)="usdValueChange($event)">  
                    <span class="c-currency">{{activeTab === 0 ? words['invest']?.['ltc-symbol']?.[lang.slug] : words['invest']?.['btc-symbol']?.[lang.slug]}}</span>
                </div>
            </div> 
            <div class="c-input">
                <div class="input-title">{{words['invest']?.['recieve']?.[lang.slug]}}</div>
                <div class="input-body">
                    <input type="number" name="value" disabled [value]="cryptoValue">
                    <span class="c-currency">{{words['invest']?.['usd-symbol']?.[lang.slug]}}</span>
                </div>
            </div> 
        </div>
        <button class="btn c-btn" (click)="onPromoBtnClick()"><span>{{words['invest']?.['power-button']?.[lang.slug]}}</span></button>
    </div>
</div>
