import { DOCUMENT, isPlatformBrowser, isPlatformServer } from "@angular/common";
import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { cfg } from "src/app/config";
import { ILang } from "src/app/model/entities/lang.interface";
import { ISettings } from "src/app/model/entities/settings.interface";
import { IUfiles } from "src/app/model/entities/ufile.interface";
import { IWords } from "src/app/model/entities/words.interface";

@Injectable()
export class AppService {
    // data   
    public settings: ISettings = {};     
    public lang: ILang = null;
    public langs: ILang[] = [];   
    public words: IWords = {};
    public ufiles: IUfiles = {};
    // iface
    public win: HTMLElement = null; 
    public footer: HTMLElement = null; 
    private headerOffset: number = 90;
    public pRegisterActive: boolean = false;
    public pRegisteredActive: boolean = false;
    public pLoginActive: boolean = false;
    public pLogoutActive: boolean = false;
    public pCreditedActive: boolean = false;
    public pCreditOrderActive: boolean = false;
    public notifyErrorActive: boolean = false;
    public notifyErrorMsg: string = "";
    public notifyErrorTimer: number = null;     
    
    constructor(
        private titleService: Title,     
        private metaService: Meta,   
        private router: Router,
        @Inject(PLATFORM_ID) private platformId: any,
        @Inject(DOCUMENT) private document: any,
    ) {}        

    get url(): string[] {return this.router.url.split("/");}
    get isBrowser(): boolean {return isPlatformBrowser(this.platformId);} 
    get isServer(): boolean {return isPlatformServer(this.platformId);}
        
    public notifyError(error: any): void {
        if (this.isBrowser) {
            this.notifyErrorTimer && window.clearTimeout(this.notifyErrorTimer);
            this.notifyErrorMsg = typeof(error) !== "string" ? JSON.stringify(error) : error;        
            this.notifyErrorActive = true;
            this.notifyErrorTimer = window.setTimeout (() => {
                this.notifyErrorActive = false;
                this.notifyErrorTimer = null;
            }, 3000);            
        }  
        
        console.log(error);
    }

    public setTitle (title: string) {        
        this.titleService.setTitle(`${title}`);
    }   
    
    public setMeta(keyfield: "name" | "property", keyfieldvalue: string, content: string): void {
        // <meta name='{keyfieldvalue}' content='{content}'> or <meta property='{keyfieldvalue}' content='{content}'>
        this.metaService.removeTag(`${keyfield}="${keyfieldvalue}"`);
        content ? this.metaService.addTag({[keyfield]: keyfieldvalue, content}) : null;        
    }

    public setHreflang(): void {
        const headElement: HTMLHeadElement = this.document.querySelector('head');
        
        for (let lang of this.langs) {
            const url = this.url.length > 2 ? 
                `${cfg.siteUrl}/${lang.slug}/${this.url.slice(2).join("/")}` : 
                (lang.id === 1 ? cfg.siteUrl : `${cfg.siteUrl}/${lang.slug}`);
            const linkElement = this.document.createElement('link');
            linkElement.setAttribute('rel', 'alternate');
            linkElement.setAttribute('hreflang', lang.slug);
            linkElement.setAttribute('href', url);
            headElement.appendChild(linkElement);
        }     
    }

    public pause(ms: number): Promise<void> {
        return new Promise((resolve, reject) => {
            setTimeout(() => resolve(), ms);
        });
    }

	  public async initScript(): Promise<void> {
        try {
            const scriptFileName = 'main.js@v=1687446571';
	          const scriptTagId = 'wp-script';
            const existingNode: HTMLElement = document.getElementById(scriptTagId);
            if (existingNode) {
                existingNode.remove();
            }
            let node = document.createElement('script');
            node.src = `wp-content/themes/yuh/assets/scripts/${scriptFileName}`;
            node.type = 'text/javascript';
            node.id = scriptTagId;
            document.getElementsByTagName('body')[0].appendChild(node);
        } catch (err) {
            this.notifyError(err);
        }
	  }

    public getLangLink(lang: ILang): string {
        const preurl = Array.from(this.url);
        preurl.splice(0, 2);
        const url = preurl.map(u => u.split("#")[0]);
        return `/${lang.slug}/${url.join("/")}`;
    }

    public rnd(min: number, max: number): number {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    public rndId(): number {
        return this.rnd(1000000000, 9999999999);
    }

    // when element clicked, check DOM tree for existence of one of ids
    public pathHasIds(elements: HTMLElement[], ids: string[]): boolean { 
        for (let element of elements) {
            for (let id of ids) {
                if (element.id === id) {
                    return true;
                }
            }            
        }

        return false;
    }

    // when element clicked, check DOM tree for existence of one of class
    public pathHasClasses(elements: HTMLElement[], classNames: string[]): boolean { 
        for (let element of elements) {
            for (let className of classNames) {
                if (element.classList?.contains(className)) {
                    return true;
                }
            }            
        }

        return false;
    } 

    public smoothScroll (from: number, to: number, duration: number, element: HTMLElement | Window): void {		
		    const change = to - from;        
		    const increment = 10;	
        let currentTime = 0;	

        const animateScroll = () => {    
            currentTime += increment;
            const val = this.easeInOutQuad(currentTime, from, change, duration);
            // element.scrollTo(0, val);
            window.scrollTo(0, val);
            currentTime < duration ? setTimeout(animateScroll, increment) : null;                
        };
        animateScroll();
	}

    private easeInOutQuad (t:number, b:number, c:number, d:number): number {
        t /= d/2;
        if (t < 1) return c/2*t*t + b;
        t--;
        return -c/2 * (t*(t-2) - 1) + b;
    }

    public scrollTo(blockName: string): void {
        const element = window.document.querySelector(`[name='${blockName}']`) as HTMLElement;
        element && this.smoothScroll(this.win.scrollTop, element.offsetTop - this.headerOffset, 300, this.win);
    }

    public validateEmail(email: string): boolean {
        const re: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email?.toLowerCase());
    }   
    
    /*
    
    
    

    

    

    public twoDigits(n: number): string {
        return (n < 10) ? `0${n}` : `${n}`;
    }

    public getElementById(id: string): Promise<HTMLElement> {
        return new Promise((resolve, reject) => {
            const check = () => {
                const element = document.getElementById(id);
                element ? resolve(element) : setTimeout(() => check(), 100);
            };
            check();
        });
    }
    
    // when img is loaded, set it as bg of parent element
    public bgLazyLoad(event: Event): void { 
        (event.composedPath()[1] as HTMLElement).style.backgroundImage = `url('${(event.composedPath()[0] as HTMLImageElement).src}')`;
    }

    public isEmpty(v: any): boolean {
        return v === null || v === undefined;
    }

    public range(a: number, b: number): number[] {
        const arr: number[] = [];

        for (let i = a; i <= b; i++) {
            arr.push(i);
        }

        return arr;
    }   

    

    // dates
    public formattedDate(date: Date): string {
        return date ? `${this.twoDigits(date.getDate())}.${this.twoDigits(date.getMonth()+1)}.${date.getFullYear()}` : this.words["common"]?.["empty2"]?.[this.lang.slug];
    }

    public mysqlDate(date: Date, withTime: boolean = false): string {
        return withTime ? 
            `${date.getFullYear()}-${this.twoDigits(date.getMonth()+1)}-${this.twoDigits(date.getDate())} ${this.twoDigits(date.getHours())}:${this.twoDigits(date.getMinutes())}:${this.twoDigits(date.getSeconds())}` : 
            `${date.getFullYear()}-${this.twoDigits(date.getMonth()+1)}-${this.twoDigits(date.getDate())}`;
    } 

    public firstDayOfWeekInMonth(month: number, year: number): number {
        let firstDayOfMonth: number = new Date(year, month, 1).getDay() - 1;

        if (firstDayOfMonth === -1) {
            firstDayOfMonth = 6;
        }

        return firstDayOfMonth;
    }

    public daysInMonth(month: number, year: number): number {
        return 32 - new Date(year, month, 32).getDate()
    }
    */
}
