export default {
  "width": "770",
  "height": "450",
  "symbolsGroups": [

    {
      "name": "Crypto",
      "symbols": [
        {
          "name": "COINBASE:BTCUSD",
          "displayName": "Bitcoin"
        },
        {
          "name": "COINBASE:ETHUSD",
          "displayName": "Ethereum"
        },
        {
          "name": "COINBASE:ADAUSD",
          "displayName": "Cardano"
        },
        {
          "name": "COINBASE:SOLUSD",
          "displayName": "Solana"
        },
        {
          "name": "BITSTAMP:XRPUSD",
          "displayName": "XRP"
        },
        {
          "name": "COINBASE:XTZUSD",
          "displayName": "Tezos"
        },
        {
          "name": "COINBASE:DOTUSD",
          "displayName": "Polkadot"
        },
        {
          "name": "COINBASE:LINKUSD",
          "displayName": "Chainlink"
        },
        {
          "name": "COINBASE:ZRXUSD",
          "displayName": "0x"
        },
        {
          "name": "COINBASE:HBARUSD",
          "displayName": "Hedera "
        }
      ]
    }
  ],
  "showSymbolLogo": true,
  "colorTheme": "light",
  "isTransparent": false,
  "locale": "en",
  "largeChartUrl": ""
};
