import { Component, OnChanges, SimpleChanges } from "@angular/core";
import { AppService } from "src/app/common/services/app.service";
import { AuthService } from "src/app/common/services/auth.service";
import { IUserRegister } from "src/app/model/dto/user.register.interface";
import { IKeyValue } from "src/app/model/keyvalue.interface";
import { PopupComponent } from "../popup.component";

@Component({
    selector: "popup-credit-order",
    templateUrl: "popup-credit-order.component.html",
    styleUrls: [
        "../../../styles/_general.scss",
		    "../../../styles/_layout.scss",
		    "../../../styles/_helpers.scss",
        "../popup.component.scss",
        "../../../styles/forms.scss",
    ],
})
export class PopupCreditOrderComponent extends PopupComponent implements OnChanges {
    public name: string = "";
    public phone: string = "";
    public email: string = "";
    public citizenship: string = "";
    public year: string = "";
    public criminal: string = "";
    public credits: string = "";
    public usd: string = "";
    public youwant: string = "";
    public errors: IKeyValue<string> = {};
    public errorExists: boolean = false;
    public loading: boolean = false;    

    constructor(
        protected appService: AppService,
        protected authService: AuthService,
    ) 
    {
        super(appService);
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (!this.active) {
            this.errors = {};
        }
    }

    public async login(): Promise<void> {
        this.onClose();
        await this.appService.pause(500);
        this.appService.pLoginActive = true;
    }

    public removeNonLatin(param: string): void {
        this[param] = this[param].replace(/[^a-z0-9\s-]/gi, ''); // latin, digits, space, dash
    }

    public removeNonDigit(param: string): void {
        this[param] = this[param].replace(/\D/g, ''); 
    }

    public async onSubmit(): Promise<void> {
        try {
            if (!this.validate()) {
                return;
            }

            // this.loading = true;
            // this.errorExists = false;
            // const dto: IUserRegister = {
            //     lang_id: this.lang.id,
            //     email: this.email, 
            //     password: this.password,
            //     lastname: this.name,
            //     firstname: this.firstname,
            //     middlename: this.middlename,
            //     addr: this.addr,
            //     phone: this.phone,
            // };
            // const statusCode = await this.authService.register(dto);
            // this.loading = false;
            //
            // if (statusCode === 201) {
            //     this.onClose();   
            //     await this.appService.pause(500);   
            //     this.appService.pRegisteredActive = true;                          
            // } else if (statusCode === 409) {
            //     this.errorExists = true;                    
            // } else {
            //     this.onClose();  
            //     this.appService.notifyError(this.words['errors']?.['common']?.[this.lang.slug]);
            // } 

            this.name = "";
            this.phone = "";
            this.email = "";
            this.citizenship = "";
            this.year = "";
            this.criminal = "";
            this.credits = "";
            this.usd = "";
            this.youwant = "";
            this.onClose();  
        } catch (err) {
            this.appService.notifyError(err);
            this.loading = false;
        }
    }

    private validate(): boolean {
        let error = false;

        if (!this.name) {
            this.errors["name"] = "required";
            error = true;
        } else {
            this.errors["name"] = null;
        }

        if (!this.phone) {
            this.errors["phone"] = "required";
            error = true;
        } else {
            this.errors["phone"] = null;
        }

        if (!this.appService.validateEmail(this.email)) {
            this.errors["email"] = "email";
            error = true;
        } else {
            this.errors["email"] = null;
        }

        if (!this.appService.validateEmail(this.email)) {
            this.errors["citizenship"] = "citizenship";
            error = true;
        } else {
            this.errors["citizenship"] = null;
        }

        if (!this.appService.validateEmail(this.email)) {
            this.errors["year"] = "year";
            error = true;
        } else {
            this.errors["year"] = null;
        }

        if (!this.appService.validateEmail(this.email)) {
            this.errors["criminal"] = "criminal";
            error = true;
        } else {
            this.errors["criminal"] = null;
        }

        if (!this.appService.validateEmail(this.email)) {
            this.errors["required"] = "required";
            error = true;
        } else {
            this.errors["required"] = null;
        }

        if (!this.appService.validateEmail(this.email)) {
            this.errors["required"] = "required";
            error = true;
        } else {
            this.errors["required"] = null;
        }

        if (!this.appService.validateEmail(this.email)) {
            this.errors["required"] = "required";
            error = true;
        } else {
            this.errors["required"] = null;
        }

        return !error;
    }
}
