<div class="module module--hero-general" data-name="hero-general" id="">
  <div class="hero-general" data-component='{ "component": "hero-general" }' data-story-devices="|all|">
    <div class="hero-base"></div>
    <div class="hero-general__back">
      <div class="hero-general__gradient"></div>
      <div class="hero-general__phones">
        <picture class="">
          <img class="lazyload hero-general__phone hero-general__phone__0 active" data-sizes="auto"
            data-srcset="wp-content/themes/yuh/assets/images/hero-general/mob1.png" alt="Phone Screen Savings"
            style="" />
        </picture>
        <picture class="">
          <img class="lazyload hero-general__phone hero-general__phone__1" data-sizes="auto"
            data-srcset="wp-content/themes/yuh/assets/images/hero-general/mob2.png" alt="Phone Screen Debit Card"
            style="" />
        </picture>
        <picture class="">
          <img class="lazyload hero-general__phone hero-general__phone__2" data-sizes="auto"
            data-srcset="wp-content/themes/yuh/assets/images/hero-general/mob3.png" alt="Phone Screen Swiss Blue Chips"
            style="" />
        </picture>
      </div>
      <img src="wp-content/themes/yuh/assets/images/hero-general/shape-mobile-0.svg" alt="background shape"
        class="hero-general__shape-mobile hero-general__shape-mobile__0">
      <img src="wp-content/themes/yuh/assets/images/hero-general/shape-mobile-1.svg" alt="background shape"
        class="hero-general__shape-mobile hero-general__shape-mobile__1">
      <img src="wp-content/themes/yuh/assets/images/hero-general/shape-mobile-2.svg" alt="background shape"
        class="hero-general__shape-mobile hero-general__shape-mobile__2">
      <img src="wp-content/themes/yuh/assets/images/hero-general/left-footer.png" alt="background shape"
        class="hero-general__shape-desktop hero-general__shape-desktop__0">
      <img src="wp-content/themes/yuh/assets/images/hero-general/left-footer.png" alt="background shape"
        class="hero-general__shape-desktop hero-general__shape-desktop__1">
      <img src="wp-content/themes/yuh/assets/images/hero-general/left-footer.png" alt="background shape"
        class="hero-general__shape-desktop hero-general__shape-desktop__2">
      <img src="wp-content/themes/yuh/assets/images/hero-general/woman-yuh.png" alt="background shape"
        class="hero-general__shape-desktop hero-general__shape-desktop__3">
    </div>
    <div class="hero-general__front">
      <div class="hero-general__header container">
        <h1 class="d1 hero-general__headline active"><span>{{words['home']?.['hero-title1']?.[lang.slug]}}</span><br />{{words['home']?.['hero-subtitle']?.[lang.slug]}}</h1>
        <h1 class="d1 hero-general__headline "><span>{{words['home']?.['hero-title2']?.[lang.slug]}}</span><br />{{words['home']?.['hero-subtitle']?.[lang.slug]}}</h1>
        <h1 class="d1 hero-general__headline "><span>{{words['home']?.['hero-title3']?.[lang.slug]}}</span><br />{{words['home']?.['hero-subtitle']?.[lang.slug]}}</h1>
        <p class="copy-lg">{{words['home']?.['hero-text']?.[lang.slug]}}<br />
        </p>
        <a
          (click)="pRegisterActive = true"
          class="button button--style--primary button--size--default hero-general__button"
          data-ga4-trigger-params='{"event":"create_free_account"," link_to":"#app-download","placement":"homepage_header"}'
          style="margin-bottom: 10px"
          >
          <div class="button__inner">
            <span class="button__label">{{words['home']?.['hero-register']?.[lang.slug]}}</span>

          </div>
        </a>
        <a
          (click)="pLoginActive = true"
          class="button button--style--primary button--size--default hero-general__button login-button"
          data-ga4-trigger-params='{"event":"create_free_account"," link_to":"#app-download","placement":"homepage_header"}'>
          <div class="button__inner">
            <span class="button__label">{{words['header']?.['login']?.[lang.slug]}}</span>

          </div>
        </a>
      </div>
    </div>
  </div>

</div>
