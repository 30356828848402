<div class="module module--security module--space-medium module--bg-image" data-name="security" id="">
  <div class="module__bg-image module__bg-image --position-buttom">
    <div class="module__bg-image__img --tablet"
      style="--src:url('wp-content/uploads/sites/2/2023/06/yuh-bg-desktop-purple-05.png')"></div>
  </div>
  <div class="security " data-component='{"component": "security"}'>
    <div class="container security__inner">
      <div class="security__inner__card ">

        <div class="security__inner__card__illustrations">
          <picture class="security__inner__card__illustrations__illustration-picture">
            <img class="lazyload yuh-person security__inner__card__illustrations__illustration-img" data-sizes="auto"
              data-srcset="https://www.yuh.com/en/wp-content/uploads/sites/2/2022/12/yuh-person.svg 760w"
              alt="Yuh Protect Person Securtity" style="" />
          </picture>
          <picture class="security__inner__card__illustrations__illustration-picture">
            <img class="lazyload lock-black js-lock-black security__inner__card__illustrations__illustration-img"
              data-sizes="auto"
              data-srcset="https://www.yuh.com/en/wp-content/uploads/sites/2/2022/12/lock-black.svg 760w"
              alt="Lock Black" style="" />
          </picture>
          <picture class="security__inner__card__illustrations__illustration-picture">
            <img class="lazyload lock-orange js-lock-orange security__inner__card__illustrations__illustration-img"
              data-sizes="auto"
              data-srcset="https://www.yuh.com/en/wp-content/uploads/sites/2/2022/12/lock-orange.svg 760w"
              alt="Lock Orange" style="" />
          </picture>
        </div>
        <div class="security__inner__card__content">
          <h3 class="h4 security__inner__card__content__headline">
            <p
              data-pm-slice="1 1 [&quot;table&quot;,{&quot;isNumberColumnEnabled&quot;:false,&quot;layout&quot;:&quot;default&quot;,&quot;__autoSize&quot;:false,&quot;localId&quot;:&quot;3b62ea01-aa33-4adb-97f6-279ccd1368f1&quot;},&quot;tableRow&quot;,null,&quot;tableCell&quot;,{&quot;colspan&quot;:1,&quot;rowspan&quot;:1,&quot;colwidth&quot;:null,&quot;background&quot;:null}]">
              {{words['home']?.['security-title']?.[lang.slug]}}<br />
          </h3>
          <div class="security__inner__card__content__partners">
            <picture class="security__inner__card__content__partners__partner-picture">
              <img class="lazyload security__inner__card__content__partners__partner-img" data-sizes="auto"
                data-srcset="https://www.yuh.com/en/wp-content/uploads/sites/2/2022/12/Swissquote.svg 760w"
                alt="Swissquote Logo" style="" />
            </picture>
            <picture class="security__inner__card__content__partners__partner-picture">
              <img class="lazyload security__inner__card__content__partners__partner-img" data-sizes="auto"
                data-srcset="https://www.yuh.com/en/wp-content/uploads/sites/2/2022/12/PostFinance.svg 760w"
                alt="PostFinance Logo" style="" />
            </picture>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="module module--about_us module--space-small module--bg-image" data-name="about_us" id="">
    <div class="module__bg-image module__bg-image --position-top">
      <div class="module__bg-image__img --tablet"
        style="--src:url('wp-content/uploads/sites/2/2023/06/yuh-bg-desktop-purple-03.png')"></div>
      <div class="module__bg-image__img "
        style="--src:url('wp-content/uploads/sites/2/2023/06/yuh-bg-mobile-purple-02.png')"></div>
    </div>

    <!-- <div class="about-us " data-component='{ "component": "about-us" }'> -->
    <div class="about-us">
      <div class="about-us__container container">
        <h1 class="h6 about-us__up-title weight-extrabold">{{words['home']?.['about-why-rdd']?.[lang.slug]}}</h1>
        <h2 class="h1 about-us__text js-about-us__text">{{words['home']?.['about-text']?.[lang.slug]}}</h2>
        <a routerLink="/{{lang.slug}}/about" class="button button--style--secondary button--size--default"
          data-ga4-trigger-params='{"event":"why_yuh_teaser","link_to":"en\/about-yuh\/"}'>
          <div class="button__inner">
            <span class="button__label">{{words['home']?.['about-learn-more']?.[lang.slug]}}</span>
          </div>
        </a>
      </div>
    </div>

  </div>


  <div class="module module--stepper module--space-big module--bg-image" data-name="stepper" id="">
    <div class="module__bg-image module__bg-image --position-top">
      <div class="module__bg-image__img --tablet"
        style="--src:url('wp-content/uploads/sites/2/2023/06/yuh-bg-desktop-purple-04.png')"></div>
      <div class="module__bg-image__img "
        style="--src:url('wp-content/uploads/sites/2/2023/06/yuh-bg-mobile-purple-06.png')"></div>
    </div>

    <!-- <div class="stepper  stepper--scrolltrigger" data-component='{ "component": "stepper" }' -->
    <div class="stepper  stepper--scrolltrigger" 
      data-settings='{"scrollTrigger":true,"slideToEvent":"stepper-64946d8ea887f-step-change","sliderName":"stepper-64946d8ea887f"}'
      style="--progress: 0; --step: 1; --total: 3;">
      <div class="stepper__pin js-stepper__pin">
        <!-- <div class="stepper__snap js-stepper__snap"></div> -->
        <div class="stepper__bg js-stepper__bg">
          <div class="stepper__outer js-stepper__outer">
            <div class="stepper__inner container">
              <div class="stepper__header js-stepper__header">
                <h2 class="h2 stepper__headline weight-extrabold">{{words['home']?.['stepper-get-started']?.[lang.slug]}}<br />
                </h2>
                <p class="copy-lg">
                <p
                  data-pm-slice="1 1 [&quot;table&quot;,{&quot;isNumberColumnEnabled&quot;:false,&quot;layout&quot;:&quot;default&quot;,&quot;__autoSize&quot;:false,&quot;localId&quot;:&quot;597b2e7e-ec29-4f9d-a47f-e219b1c0df99&quot;},&quot;tableRow&quot;,null,&quot;tableCell&quot;,{&quot;colspan&quot;:1,&quot;rowspan&quot;:1,&quot;colwidth&quot;:null,&quot;background&quot;:null}]">
                  {{words['home']?.['stepper-text1']?.[lang.slug]}}<br />
                <p
                  data-pm-slice="1 1 [&quot;table&quot;,{&quot;isNumberColumnEnabled&quot;:false,&quot;layout&quot;:&quot;default&quot;,&quot;__autoSize&quot;:false,&quot;localId&quot;:&quot;597b2e7e-ec29-4f9d-a47f-e219b1c0df99&quot;},&quot;tableRow&quot;,null,&quot;tableCell&quot;,{&quot;colspan&quot;:1,&quot;rowspan&quot;:1,&quot;colwidth&quot;:null,&quot;background&quot;:null}]">
                  {{words['home']?.['stepper-text2']?.[lang.slug]}}<br />
                </p>
              </div>
              <div class="stepper__content js-stepper__content">
                <!-- <div class="stepper__body stepper__mobile js-stepper__body"> -->
                <!--   <div class="stepper__mobile__item js-stepper__item is-open"> -->
                <!--     <div class="stepper__mobile__head js-stepper__item__head"> -->
                <!--       <div class="stepper__mobile__head__left"> -->
                <!--         1 </div> -->
                <!--       <h5 class="h5 stepper__mobile__head__title">Get the app<br /> -->
                <!--       </h5> -->
                <!--     </div> -->
                <!--     <div class="stepper__mobile__body js-stepper__item__body"> -->
                <!--       <div class="stepper__mobile__body__inner"> -->
                <!--         <div class="stepper__mobile__body__description copy"> -->
                <!--           <p -->
                <!--             data-pm-slice="1 1 [&quot;table&quot;,{&quot;isNumberColumnEnabled&quot;:false,&quot;layout&quot;:&quot;default&quot;,&quot;__autoSize&quot;:false,&quot;localId&quot;:&quot;597b2e7e-ec29-4f9d-a47f-e219b1c0df99&quot;},&quot;tableRow&quot;,null,&quot;tableCell&quot;,{&quot;colspan&quot;:1,&quot;rowspan&quot;:1,&quot;colwidth&quot;:null,&quot;background&quot;:null}]"> -->
                <!--             Download the Yuh app from the App Store, Google Play Store or Huawei App Gallery.<br /> -->
                <!--         </div> -->
                <!--         <picture class="stepper__mobile__body__image"> -->
                <!--           <img class="lazyload " data-sizes="auto" -->
                <!--             data-srcset="wp-content/themes/yuh/assets/images/hero-general/logo.png" -->
                <!--             alt="Phone Screen QR Code" style="" /> -->
                <!--         </picture> -->
                <!--       </div> -->
                <!--     </div> -->
                <!--   </div> -->
                <!--   <div class="stepper__mobile__item js-stepper__item "> -->
                <!--     <div class="stepper__mobile__head js-stepper__item__head"> -->
                <!--       <div class="stepper__mobile__head__left"> -->
                <!--         2 </div> -->
                <!--       <h5 class="h5 stepper__mobile__head__title">Create your account<br /> -->
                <!--       </h5> -->
                <!--     </div> -->
                <!--     <div class="stepper__mobile__body js-stepper__item__body"> -->
                <!--       <div class="stepper__mobile__body__inner"> -->
                <!--         <div class="stepper__mobile__body__description copy"> -->
                <!--           <p -->
                <!--             data-pm-slice="1 1 [&quot;table&quot;,{&quot;isNumberColumnEnabled&quot;:false,&quot;layout&quot;:&quot;default&quot;,&quot;__autoSize&quot;:false,&quot;localId&quot;:&quot;597b2e7e-ec29-4f9d-a47f-e219b1c0df99&quot;},&quot;tableRow&quot;,null,&quot;tableCell&quot;,{&quot;colspan&quot;:1,&quot;rowspan&quot;:1,&quot;colwidth&quot;:null,&quot;background&quot;:null}]"> -->
                <!--             Enter your personal details and verify your identity in the app.<br /> -->
                <!--         </div> -->
                <!--         <picture class="stepper__mobile__body__image"> -->
                <!--           <img class="lazyload " data-sizes="auto" -->
                <!--             data-srcset="https://www.yuh.com/en/wp-content/uploads/sites/2/2023/06/yuh-id-1.svg" -->
                <!--             alt="yuh face recognition" style="" /> -->
                <!--         </picture> -->
                <!--       </div> -->
                <!--     </div> -->
                <!--   </div> -->
                <!--   <div class="stepper__mobile__item js-stepper__item "> -->
                <!--     <div class="stepper__mobile__head js-stepper__item__head"> -->
                <!--       <div class="stepper__mobile__head__left"> -->
                <!--         3 </div> -->
                <!--       <h5 class="h5 stepper__mobile__head__title">Top up your balance<br /> -->
                <!--       </h5> -->
                <!--     </div> -->
                <!--     <div class="stepper__mobile__body js-stepper__item__body"> -->
                <!--       <div class="stepper__mobile__body__inner"> -->
                <!--         <div class="stepper__mobile__body__description copy">Make your first deposit (spoiler alert: -->
                <!--           if you first fund your account with 500 CHF, you will receive 250 Swissqoins).<br /> -->
                <!--         </div> -->
                <!--         <picture class="stepper__mobile__body__image"> -->
                <!--           <img class="lazyload " data-sizes="auto" -->
                <!--             data-srcset="https://www.yuh.com/en/wp-content/uploads/sites/2/2023/03/yuh-get-balance.svg" -->
                <!--             alt="Illustration Balance Yuh Coin Phone" style="" /> -->
                <!--         </picture> -->
                <!--       </div> -->
                <!--     </div> -->
                <!--   </div> -->
                <!-- </div> -->
                <div class="stepper__body stepper__desktop js-stepper__body">
                  <div class="stepper__desktop__item js-stepper__item is-open">
                    <div class="stepper__desktop__number">
                      1 </div>
                    <h5 class="h5 stepper__desktop__title">{{words['home']?.['stepper-1-title']?.[lang.slug]}}<br />
                    </h5>
                    <h5 class="h5 stepper__desktop__title__copy">{{words['home']?.['stepper-1-title']?.[lang.slug]}}<br />
                    </h5>
                    <div class="stepper__desktop__body">
                      <div class="stepper__desktop__body__description copy">
                        <p
                          data-pm-slice="1 1 [&quot;table&quot;,{&quot;isNumberColumnEnabled&quot;:false,&quot;layout&quot;:&quot;default&quot;,&quot;__autoSize&quot;:false,&quot;localId&quot;:&quot;597b2e7e-ec29-4f9d-a47f-e219b1c0df99&quot;},&quot;tableRow&quot;,null,&quot;tableCell&quot;,{&quot;colspan&quot;:1,&quot;rowspan&quot;:1,&quot;colwidth&quot;:null,&quot;background&quot;:null}]">
                          {{words['home']?.['stepper-1-text']?.[lang.slug]}}<br />
                      </div>
                      <picture class="stepper__desktop__body__image">
                        <img class="lazyload " data-sizes="auto"
                          data-srcset="wp-content/themes/yuh/assets/images/hero-general/mobil1.png"
                          alt="Phone Screen QR Code" style="" />
                      </picture>
                    </div>
                  </div>
                  <div class="stepper__desktop__item js-stepper__item is-open">
                    <div class="stepper__desktop__number ">
                      2 </div>
                    <h5 class="h5 stepper__desktop__title">{{words['home']?.['stepper-2-title']?.[lang.slug]}}<br />
                    </h5>
                    <h5 class="h5 stepper__desktop__title__copy">{{words['home']?.['stepper-2-title']?.[lang.slug]}}<br />
                    </h5>
                    <div class="stepper__desktop__body">
                      <div class="stepper__desktop__body__description copy">
                        <p
                          data-pm-slice="1 1 [&quot;table&quot;,{&quot;isNumberColumnEnabled&quot;:false,&quot;layout&quot;:&quot;default&quot;,&quot;__autoSize&quot;:false,&quot;localId&quot;:&quot;597b2e7e-ec29-4f9d-a47f-e219b1c0df99&quot;},&quot;tableRow&quot;,null,&quot;tableCell&quot;,{&quot;colspan&quot;:1,&quot;rowspan&quot;:1,&quot;colwidth&quot;:null,&quot;background&quot;:null}]">
                          {{words['home']?.['stepper-2-text']?.[lang.slug]}}<br />
                      </div>
                      <picture class="stepper__desktop__body__image">
                        <img class="lazyload " data-sizes="auto"
                          data-srcset="wp-content/themes/yuh/assets/images/hero-general/has.png"
                          alt="yuh face recognition" style="" />
                      </picture>
                    </div>
                  </div>
                  <div class="stepper__desktop__item js-stepper__item is-open ">
                    <div class="stepper__desktop__number">
                      3 </div>
                    <h5 class="h5 stepper__desktop__title">{{words['home']?.['stepper-3-title']?.[lang.slug]}}<br />
                    </h5>
                    <h5 class="h5 stepper__desktop__title__copy">{{words['home']?.['stepper-3-title']?.[lang.slug]}}<br />
                    </h5>
                    <div class="stepper__desktop__body">
                      <div class="stepper__desktop__body__description copy">
                      {{words['home']?.['stepper-3-text']?.[lang.slug]}}<br />
                      </div>
                      <picture class="stepper__desktop__body__image">
                        <img class="lazyload " data-sizes="auto"
                          data-srcset="wp-content/themes/yuh/assets/images/hero-general/les.png"
                          alt="Illustration Balance RDD Coin Phone" style="" />
                      </picture>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="news">
    <div class="container">
      <h2 class="h2 news__headline">{{words['home']?.['news-title']?.[lang.slug]}}</h2>
      <div class="news__content">
        <div class="news__slider" data-component='{ "component": "slider" }'
          data-slider-settings='{ "slidesPerView": 1, "spaceBetween": 24, "navigation": true, "pagination": false, "paginationType": "custom" }'>
          <div class="news__item">
            <picture class="news__item__image">
              <img class="lazyload " data-sizes="auto"
                data-srcset="wp-content/themes/yuh/assets/images/hero-general/bank.jpg" style="" />
            </picture>
            <div class="news__item__content">
              <h4 class="h4 news__title">{{words['home']?.['news-slide1-title']?.[lang.slug]}}</h4>
              <div class="news__description copy-sm">{{words['home']?.['news-slide1-text']?.[lang.slug]}}<br />
              </div>
              <a routerLink="/{{lang.slug}}/save" class="button button--style--link button--size--default news__button"
                data-ga4-trigger-params='{"event":"news_teaser","link_to":"en\/app\/save\/rates\/"}'>
                <div class="button__inner">
                  <div class="icon button__icon button__icon--right"><svg xmlns="http://www.w3.org/2000/svg" width="24"
                      height="24" viewBox="0 0 24 24">
                      <path
                        d="M20.383 12.578a1.5 1.5 0 0 0-.323-1.64l-7-7a1.5 1.5 0 0 0-2.12 2.122l4.44 4.44H5a1.5 1.5 0 0 0 0 3h10.38l-4.44 4.44a1.5 1.5 0 0 0 2.084 2.16l.037-.037 7-7a1.5 1.5 0 0 0 .322-.485Z"
                        fill="currentColor" />
                    </svg></div> <span class="button__label">{{words['home']?.['news-slide-learn-more']?.[lang.slug]}}</span>
                </div>
              </a>
            </div>
          </div>
          <div class="news__item">
            <picture class="news__item__image">
              <img class="lazyload " data-sizes="auto"
                data-srcset="wp-content/themes/yuh/assets/images/hero-general/twin.png" alt="Phone Screen Twint and yuh"
                style="" />
            </picture>
            <div class="news__item__content">
              <h4 class="h4 news__title">{{words['home']?.['news-slide2-title']?.[lang.slug]}}</h4>
              <div class="news__description copy-sm">{{words['home']?.['news-slide2-text']?.[lang.slug]}}<br />
              </div>
              <a routerLink="/{{lang.slug}}/save" class="button button--style--link button--size--default news__button"
                data-ga4-trigger-params='{"event":"news_teaser","link_to":"en\/app\/pay\/twint\/"}'>
                <div class="button__inner">
                  <div class="icon button__icon button__icon--right"><svg xmlns="http://www.w3.org/2000/svg" width="24"
                      height="24" viewBox="0 0 24 24">
                      <path
                        d="M20.383 12.578a1.5 1.5 0 0 0-.323-1.64l-7-7a1.5 1.5 0 0 0-2.12 2.122l4.44 4.44H5a1.5 1.5 0 0 0 0 3h10.38l-4.44 4.44a1.5 1.5 0 0 0 2.084 2.16l.037-.037 7-7a1.5 1.5 0 0 0 .322-.485Z"
                        fill="currentColor" />
                    </svg></div> <span class="button__label">{{words['home']?.['news-slide-learn-more']?.[lang.slug]}}</span>
                </div>
              </a>
            </div>
          </div>
          <div class="news__item">
            <picture class="news__item__image">
              <img class="lazyload " data-sizes="auto"
                data-srcset="https://www.yuh.com/en/wp-content/uploads/sites/2/fly-images/4823/whatsnew-investmentproducts-650x9999.png 400w"
                alt="Investment Products Shopping" style="" />
            </picture>
            <div class="news__item__content">
              <h4 class="h4 news__title">{{words['home']?.['news-slide3-title']?.[lang.slug]}}</h4>
              <div class="news__description copy-sm">{{words['home']?.['news-slide3-text']?.[lang.slug]}}<br />
              </div>
              <a routerLink="/{{lang.slug}}/save"
                class="button button--style--link button--size--default news__button"
                data-ga4-trigger-params='{"event":"news_teaser","link_to":"en\/app\/invest\/stocks\/"}'>
                <div class="button__inner">
                  <div class="icon button__icon button__icon--right"><svg xmlns="http://www.w3.org/2000/svg" width="24"
                      height="24" viewBox="0 0 24 24">
                      <path
                        d="M20.383 12.578a1.5 1.5 0 0 0-.323-1.64l-7-7a1.5 1.5 0 0 0-2.12 2.122l4.44 4.44H5a1.5 1.5 0 0 0 0 3h10.38l-4.44 4.44a1.5 1.5 0 0 0 2.084 2.16l.037-.037 7-7a1.5 1.5 0 0 0 .322-.485Z"
                        fill="currentColor" />
                    </svg></div> <span class="button__label">{{words['home']?.['news-slide-learn-more']?.[lang.slug]}}</span>
                </div>
              </a>
            </div>
          </div>
          <div class="news__item">
            <picture class="news__item__image">
              <img class="lazyload " data-sizes="auto"
                data-srcset="wp-content/themes/yuh/assets/images/hero-general/girl1.jpg" alt="eBill Person Image"
                style="" />
            </picture>
            <div class="news__item__content">
              <h4 class="h4 news__title">{{words['home']?.['news-slide4-title']?.[lang.slug]}}</h4>
              <div class="news__description copy-sm">{{words['home']?.['news-slide4-text']?.[lang.slug]}}<br />
              </div>
              <a routerLink="/{{lang.slug}}/save" class="button button--style--link button--size--default news__button"
                data-ga4-trigger-params='{"event":"news_teaser","link_to":"en\/app\/pay\/ebill\/"}'>
                <div class="button__inner">
                  <div class="icon button__icon button__icon--right"><svg xmlns="http://www.w3.org/2000/svg" width="24"
                      height="24" viewBox="0 0 24 24">
                      <path
                        d="M20.383 12.578a1.5 1.5 0 0 0-.323-1.64l-7-7a1.5 1.5 0 0 0-2.12 2.122l4.44 4.44H5a1.5 1.5 0 0 0 0 3h10.38l-4.44 4.44a1.5 1.5 0 0 0 2.084 2.16l.037-.037 7-7a1.5 1.5 0 0 0 .322-.485Z"
                        fill="currentColor" />
                    </svg></div> <span class="button__label">{{words['home']?.['news-slide-learn-more']?.[lang.slug]}}</span>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
