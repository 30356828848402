import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from '@angular/forms';
import { RouterModule } from "@angular/router";
import { ComponentsModule } from "src/app/common/components/components.module";
import { AboutUsComponent } from "./components/about-us/about-us.component";
import { ArticleComponent } from "./components/article/article.component";
import { CaruselComponent } from "./components/carusel/carusel.component";
import { DownloadComponent } from "./components/download/download.component";
import { ImageTextComponent } from "./components/image-text/image-text.component";
import { AboutPage } from "./pages/about.page";

let routes = RouterModule.forChild ([            
	{path: "", component: AboutPage},	
]);

@NgModule({	
  imports: [	
		CommonModule,
		RouterModule,
    FormsModule,
		ComponentsModule,
		routes,
	],
	declarations: [
		AboutPage,
		AboutUsComponent,
		ImageTextComponent,
		CaruselComponent,
		ArticleComponent,
		DownloadComponent,
	],    		    
})
export class AboutModule {}
