<div class="module module--faq module--space-small module--bg-image" data-name="faq" id="">
  <div class="module__bg-image module__bg-image --position-top">
    <div class="module__bg-image__img --tablet"
      style="--src:url('wp-content/uploads/sites/2/2023/06/yuh-bg-desktop-purple-05.png')"></div>
  </div>
  <div class="loop-faq">
    <div class="container">
      <div class="loop-faq__top">
        <h2 class="h2 loop-faq__top__headline">{{words['home']?.['faq-title']?.[lang.slug]}}<br />
        </h2>
      </div>
      <div class="loop-faq__main">
        <div class="loop-faq__accordion">

          <div class="accordion" data-component='{ "component": "accordion" }' data-settings='{"closeOthers":true}'>
            <div class="
						accordion__item 
						js-accordion__item 
											">
              <div class="accordion__head js-accordion__item__head" id="c8f80d2b5c5e9e14030b080e3eaf92b9"
                data-ga4-trigger-params='{"event":"read_faqs","question":"Is RDD a Franse bank?"}'>
                <div class="loop-faq__item__title h6">{{words['home']?.['faq-q1']?.[lang.slug]}}</div>
                <div class="accordion__head__right">
                  <button
                    class="button button--style--secondary button--size--default button--icon-only loop-faq__item__button loop-faq__item__button-plus">
                    <div class="button__inner">
                      <div class="icon button__icon"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                          viewBox="0 0 16 16">
                          <path
                            d="M12.666 7H9V3.333a1 1 0 0 0-2 0V7H3.333a1 1 0 0 0 0 2H7v3.667a1 1 0 1 0 2 0V9h3.666a1 1 0 0 0 0-2Z"
                            fill="#fa5b35" />
                        </svg></div>
                    </div>
                  </button>
                  <button
                    class="button button--style--secondary button--size--default button--icon-only loop-faq__item__button loop-faq__item__button-minus">
                    <div class="button__inner">
                      <div class="icon button__icon"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                          viewBox="0 0 16 16">
                          <path d="M12.667 9H3.333a1 1 0 0 1 0-2h9.334a1 1 0 1 1 0 2Z" fill="#fa5b35" />
                        </svg></div>
                    </div>
                  </button>
                </div>
                <!-- <div class="accordion__head__arrow "> -->
                <!-- </div> -->
              </div>
              <div class="accordion__body js-accordion__item__body">
                <div class="accordion__body__inner js-accordion__item__body__inner">
                  <div class="loop-faq__item__content" [innerHTML]="words['home']?.['faq-a1']?.[lang.slug]"></div>
                </div>
              </div>
            </div>
            <div class="
						accordion__item 
						js-accordion__item 
											">
              <div class="accordion__head js-accordion__item__head" id="d866d8b6d5513009df11a60773c7ac60"
                data-ga4-trigger-params='{"event":"read_faqs","question":"How much does RDD
				 charge in fees?"}'>
                <div class="loop-faq__item__title h6">{{words['home']?.['faq-q2']?.[lang.slug]}}</div>
                <div class="accordion__head__right">
                  <button
                    class="button button--style--secondary button--size--default button--icon-only loop-faq__item__button loop-faq__item__button-plus">
                    <div class="button__inner">
                      <div class="icon button__icon"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                          viewBox="0 0 16 16">
                          <path
                            d="M12.666 7H9V3.333a1 1 0 0 0-2 0V7H3.333a1 1 0 0 0 0 2H7v3.667a1 1 0 1 0 2 0V9h3.666a1 1 0 0 0 0-2Z"
                            fill="#fa5b35" />
                        </svg></div>
                    </div>
                  </button>
                  <button
                    class="button button--style--secondary button--size--default button--icon-only loop-faq__item__button loop-faq__item__button-minus">
                    <div class="button__inner">
                      <div class="icon button__icon"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                          viewBox="0 0 16 16">
                          <path d="M12.667 9H3.333a1 1 0 0 1 0-2h9.334a1 1 0 1 1 0 2Z" fill="#fa5b35" />
                        </svg></div>
                    </div>
                  </button>
                </div>
                <!-- <div class="accordion__head__arrow "> -->
                <!-- </div> -->
              </div>
              <div class="accordion__body js-accordion__item__body">
                <div class="accordion__body__inner js-accordion__item__body__inner">
                  <div class="loop-faq__item__content" [innerHTML]="words['home']?.['faq-a2']?.[lang.slug]"></div>
                </div>
              </div>
            </div>
            <div class="
						accordion__item 
						js-accordion__item 
											">
              <div class="accordion__head js-accordion__item__head" id="f0e75276dd1bdbd987ab4dadcc16a77a"
                data-ga4-trigger-params='{"event":"read_faqs","question":"Which investment products can I trade with RDD?"}'>
                <div class="loop-faq__item__title h6">{{words['home']?.['faq-q3']?.[lang.slug]}}</div>
                <div class="accordion__head__right">
                  <button
                    class="button button--style--secondary button--size--default button--icon-only loop-faq__item__button loop-faq__item__button-plus">
                    <div class="button__inner">
                      <div class="icon button__icon"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                          viewBox="0 0 16 16">
                          <path
                            d="M12.666 7H9V3.333a1 1 0 0 0-2 0V7H3.333a1 1 0 0 0 0 2H7v3.667a1 1 0 1 0 2 0V9h3.666a1 1 0 0 0 0-2Z"
                            fill="#fa5b35" />
                        </svg></div>
                    </div>
                  </button>
                  <button
                    class="button button--style--secondary button--size--default button--icon-only loop-faq__item__button loop-faq__item__button-minus">
                    <div class="button__inner">
                      <div class="icon button__icon"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                          viewBox="0 0 16 16">
                          <path d="M12.667 9H3.333a1 1 0 0 1 0-2h9.334a1 1 0 1 1 0 2Z" fill="#fa5b35" />
                        </svg></div>
                    </div>
                  </button>
                </div>
                <!-- <div class="accordion__head__arrow "> -->
                <!-- </div> -->
              </div>
              <div class="accordion__body js-accordion__item__body">
                <div class="accordion__body__inner js-accordion__item__body__inner">
                  <div class="loop-faq__item__content" [innerHTML]="words['home']?.['faq-a3']?.[lang.slug]"></div>
                </div>
              </div>
            </div>
            <div class="
						accordion__item 
						js-accordion__item 
											">
              <div class="accordion__head js-accordion__item__head" id="a77dc125a38f8567ba58762dd5ede189"
                data-ga4-trigger-params='{"event":"read_faqs","question":"How do I get the free RDD Mastercard?"}'>
                <div class="loop-faq__item__title h6">{{words['home']?.['faq-q4']?.[lang.slug]}}</div>
                <div class="accordion__head__right">
                  <button
                    class="button button--style--secondary button--size--default button--icon-only loop-faq__item__button loop-faq__item__button-plus">
                    <div class="button__inner">
                      <div class="icon button__icon"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                          viewBox="0 0 16 16">
                          <path
                            d="M12.666 7H9V3.333a1 1 0 0 0-2 0V7H3.333a1 1 0 0 0 0 2H7v3.667a1 1 0 1 0 2 0V9h3.666a1 1 0 0 0 0-2Z"
                            fill="#fa5b35" />
                        </svg></div>
                    </div>
                  </button>
                  <button
                    class="button button--style--secondary button--size--default button--icon-only loop-faq__item__button loop-faq__item__button-minus">
                    <div class="button__inner">
                      <div class="icon button__icon"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                          viewBox="0 0 16 16">
                          <path d="M12.667 9H3.333a1 1 0 0 1 0-2h9.334a1 1 0 1 1 0 2Z" fill="#fa5b35" />
                        </svg></div>
                    </div>
                  </button>
                </div>
                <!-- <div class="accordion__head__arrow "> -->
                <!-- </div> -->
              </div>
              <div class="accordion__body js-accordion__item__body">
                <div class="accordion__body__inner js-accordion__item__body__inner">
                  <div class="loop-faq__item__content" [innerHTML]="words['home']?.['faq-a4']?.[lang.slug]"></div>
                </div>
              </div>
            </div>
            <div class="
						accordion__item 
						js-accordion__item 
											">
              <div class="accordion__head js-accordion__item__head" id="50f89c59cafdd5400c960e2aa09f1832"
                data-ga4-trigger-params='{"event":"read_faqs","question":"What do I do if my card is lost or stolen?"}'>
                <div class="loop-faq__item__title h6">{{words['home']?.['faq-q5']?.[lang.slug]}}</div>
                <div class="accordion__head__right">
                  <button
                    class="button button--style--secondary button--size--default button--icon-only loop-faq__item__button loop-faq__item__button-plus">
                    <div class="button__inner">
                      <div class="icon button__icon"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                          viewBox="0 0 16 16">
                          <path
                            d="M12.666 7H9V3.333a1 1 0 0 0-2 0V7H3.333a1 1 0 0 0 0 2H7v3.667a1 1 0 1 0 2 0V9h3.666a1 1 0 0 0 0-2Z"
                            fill="#fa5b35" />
                        </svg></div>
                    </div>
                  </button>
                  <button
                    class="button button--style--secondary button--size--default button--icon-only loop-faq__item__button loop-faq__item__button-minus">
                    <div class="button__inner">
                      <div class="icon button__icon"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                          viewBox="0 0 16 16">
                          <path d="M12.667 9H3.333a1 1 0 0 1 0-2h9.334a1 1 0 1 1 0 2Z" fill="#fa5b35" />
                        </svg></div>
                    </div>
                  </button>
                </div>
                <!-- <div class="accordion__head__arrow "> -->
                <!-- </div> -->
              </div>
              <div class="accordion__body js-accordion__item__body">
                <div class="accordion__body__inner js-accordion__item__body__inner">
                  <div class="loop-faq__item__content" [innerHTML]="words['home']?.['faq-a5']?.[lang.slug]"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
