import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AppService } from "src/app/common/services/app.service";
import { AuthService } from "src/app/common/services/auth.service";
import { CardRepository } from "src/app/common/services/repositories/card.repository";
import { PageRepository } from "src/app/common/services/repositories/page.repository";
import { ICard } from "src/app/model/entities/card.interface";
import { SimplePage } from "src/app/pages/simple.page";

@Component({
    selector: "index-account-page",
    templateUrl: "index.account.page.html",
    styleUrls: [
      "../../../../common/styles/_general.scss",
		  "../../../../common/styles/_layout.scss",
		  "../../../../common/styles/_helpers.scss",
      "../../../../common/styles/forms.scss",
      "index.account.page.scss",
    ],
})
export class IndexAccountPage extends SimplePage implements OnInit {
    public cards: ICard[] = [];

    constructor(
        protected appService: AppService,
        protected authService: AuthService,
        protected pageRepository: PageRepository,
        protected cardRepository: CardRepository,
        protected route: ActivatedRoute,
        protected router: Router,
    )
    {
        super(appService, pageRepository, route, router);
    }

    get filter(): any {return {user_id: this.authService.authData.id};}

    public async ngOnInit(): Promise<void> {
        this.initCards();
        await this.initPage('account');
        this.route.params.subscribe(p => this.initSEO());
    }

    private async initCards(): Promise<void> {
        try {
            this.cards = await this.cardRepository.loadAll("id", 1, this.filter);
        } catch (err) {
            this.appService.notifyError(err);
        }
    }
}
