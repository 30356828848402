<div class="module module--download module--space-big" data-name="download" id="">
  <div class="download">
    <div class="download__inner container">
      <div class="download__content">
        <h2 class="download__title h2">
        </h2>
        <div class="download__buttons">
          <picture class="">
            <img class="lazyload download__bg__item download__bg__item--left" data-sizes="auto"
              data-srcset="https://www.yuh.com/en/wp-content/themes/yuh/assets/images/404/illustration1.svg"
              alt="Illustration" style="" />
          </picture>
          <picture class="">
            <img class="lazyload download__bg__item download__bg__item--center" data-sizes="auto"
              data-srcset="https://www.yuh.com/en/wp-content/themes/yuh/assets/images/404/illustration3.svg"
              alt="Illustration" style="" />
          </picture>
          <picture class="">
            <img class="lazyload download__bg__item download__bg__item--right" data-sizes="auto"
              data-srcset="https://www.yuh.com/en/wp-content/themes/yuh/assets/images/404/illustration2.svg"
              alt="Illustration" style="" />
          </picture>
        </div>
      </div>
    </div>
  </div>
</div>
