<div class="module module--about_us module--space-small module--bg-image" data-name="about_us" id="">
  <div class="module__bg-image module__bg-image --position-top">
    <div class="module__bg-image__img --tablet"
      style="--src:url('uploads/sites/2/2023/06/yuh-bg-desktop-purple-03.png')"></div>
    <div class="module__bg-image__img " style="--src:url('uploads/sites/2/2023/06/yuh-bg-mobile-purple-05.png')">
    </div>
  </div>

  <div class="about-us " data-component='{ "component": "about-us" }'>
    <div class="about-us__container container">
      <h2 class="h1 about-us__text">{{words['about']?.['about-us-text']?.[lang.slug]}}</h2>
      <a (click)="pRegisterActive = true" target="_self" class="button button--style--primary button--size--default">
        <div class="button__inner">
          <span class="button__label">{{words['about']?.['create-account']?.[lang.slug]}}</span>
        </div>
      </a>
    </div>
  </div>
</div>
