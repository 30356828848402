import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from '@angular/forms';
import { RouterModule } from "@angular/router";
import { ComponentsModule } from "src/app/common/components/components.module";
import { AboutUsComponent } from "./components/about-us/about-us.component";
import { AssetsComponent } from "./components/assets/assets.component";
import { ConverterComponent } from "./components/converter/converter.component";
import { FeatureSmallComponent } from "./components/feature-small/feature-small.component";
import { FeaturesComponent } from "./components/features/features.component";
import { HeroAppComponent } from "./components/hero-app/hero-app.component";
import { ImageTextComponent } from "./components/image-text/image-text.component";
import { InvestPage } from "./pages/invest.page";

let routes = RouterModule.forChild ([            
	{path: "", component: InvestPage},	
]);

@NgModule({	
  imports: [	
		CommonModule,
		RouterModule,
    FormsModule,
		ComponentsModule,
		routes,
	],
	declarations: [
		InvestPage,
		HeroAppComponent,
		AboutUsComponent,
		AssetsComponent,
		FeaturesComponent,
		FeatureSmallComponent,
		ImageTextComponent,
		ConverterComponent,
	],    		    
})
export class InvestModule {}
