import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from "@angular/core";
import { Timeout } from "src/app/common/decorators/timeout";
import { ICard } from "src/app/model/entities/card.interface";

@Component({
  selector: "cards-slider",
  templateUrl: "cards-slider.component.html",
  styleUrls: ["cards-slider.component.scss"],
})
export class CardsSliderComponent implements OnInit, AfterViewInit {
  // data
  @Input() public cards: ICard[];
  @Output() private currentChange: EventEmitter<number> = new EventEmitter();
  // layout
  @ViewChild("win", { static: false }) private winRef: ElementRef;
  @ViewChild("container", { static: false }) containerRef: ElementRef;
  public width: number = 0;
  public height: number = 0;
  // movements
  private transition: number = 0.3;
  public step: number = 0;
  public left: number = 0;
  private startX: number = 0;
  private startXOffsetted: number = 0;
  private startY: number = 0;
  private prevX: number = 0;
  private dragging: boolean = false;
  public innerWidth: number = 0;

  public async ngOnInit(): Promise<void> {
    this.onDragEnd = this.onDragEnd.bind(this);
    this.onDragMove = this.onDragMove.bind(this);
    this.onFirstTouchMove = this.onFirstTouchMove.bind(this);

    if (this.cards.length >= 3) {
      this.cards.unshift(this.cards[this.cards.length - 1]);
      this.cards.push(this.cards[1]);
    }
  }

  get win(): HTMLElement { return this.winRef.nativeElement; }
  get container(): HTMLElement { return this.containerRef.nativeElement; }

  @Timeout(1)
  public async ngAfterViewInit(): Promise<void> {
    this.initLayout();
  }

  private initLayout(): void {
    this.innerWidth = window.innerWidth;
    this.step = 1;

    if (window.innerWidth < 450) {
      this.width = parseInt((this.win.offsetWidth / 100 * 90).toFixed());
    } else {
      this.width = 420;
    }

    this.height = this.width / 100 * 61;
  }

  @HostListener('window:resize')
  private onResize(): void {
    this.initLayout();
    this.animate();
  }

  private animate(): void {
    // const offset = this.step === this.cards.length - 1 ? this.win.offsetWidth - this.width - 30 : 0;
    // this.left = this.step * (this.width + 15) - offset;
    // this.left = this.step * (this.width + 15);
  }

  public onDragStart(event: TouchEvent | MouseEvent): void {
    this.startX = event instanceof MouseEvent ? event.clientX : event.touches[0].clientX;
    this.startY = event instanceof MouseEvent ? event.clientY : event.touches[0].clientY;
    this.startXOffsetted = this.startX - this.container.offsetLeft;

    if (event instanceof MouseEvent) { // just start dragging
      this.container.style.transition = "none";
      window.addEventListener("mousemove", this.onDragMove, { passive: false });
      window.addEventListener("mouseup", this.onDragEnd);
    } else { // detect if dragging is more by X than by Y
      window.addEventListener("touchmove", this.onFirstTouchMove);
    }
  }

  private onFirstTouchMove(event: TouchEvent): void {
    let deltaX = Math.abs(this.startX - event.touches[0].clientX);
    let deltaY = Math.abs(this.startY - event.touches[0].clientY);
    window.removeEventListener("touchmove", this.onFirstTouchMove);

    if (deltaX > deltaY) {
      this.container.style.transition = "none";
      window.addEventListener("touchmove", this.onDragMove, { passive: false });
      window.addEventListener("touchend", this.onDragEnd);
    }
  }

  private onDragMove(event: TouchEvent | MouseEvent): void {
    event.cancelable && event.preventDefault();
    const x = event instanceof MouseEvent ? event.clientX : event.touches[0].clientX;
    const nextX = this.startXOffsetted - x;
    this.left = nextX;
    this.prevX = x;

    // detect real movement, not accidental
    if (!this.dragging && Math.abs(x - this.startX) > 3) {
      this.dragging = true;
    }
  }

  private async onDragEnd(): Promise<void> {
    window.removeEventListener("mousemove", this.onDragMove);
    window.removeEventListener("touchmove", this.onDragMove);
    window.removeEventListener("mouseup", this.onDragEnd);
    window.removeEventListener("touchend", this.onDragEnd);
    this.container.style.transition = `${this.transition}s`;

    // доводка
    if (this.dragging) {
      const prevStep = this.step;

      if (this.prevX < this.startX - 30) {
        if (this.step === this.cards.length - 2) {
          this.step = Math.min(1, this.cards.length - 1);
        } else {
          this.step = Math.min(this.step + 1, this.cards.length - 1);
        }
      } else if (this.prevX > this.startX + 30) {
        if (this.step === 1) {
          this.step = Math.max(this.cards.length - 2, 0);
        } else {
          this.step = Math.max(this.step - 1, 0);
        }
      }

      prevStep !== this.step && this.currentChange.emit(this.step); // output
    }

    // final move to integer step
    this.animate();
    this.dragging = false;
  }

  public onNext(): void {
    if (this.step === this.cards.length - 2) {
      this.step = 1;
    } else {
      this.step++;
    }
    this.animate();
    this.currentChange.emit(this.step);
  }

  public onPrev(): void {
    if (this.step === 1) {
      this.step = this.cards.length - 2;
    } else {
      this.step--;
    }
    this.animate();
    this.currentChange.emit(this.step);
  }
}
