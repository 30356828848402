<div class="module module--stepper js-content-navigation__section module--space-big module--bg-image"
  data-section-index="3" data-name="stepper" id="Get started">

  <div class="stepper  stepper--scrolltrigger" data-component='{ "component": "stepper" }'
    data-settings='{"scrollTrigger":true,"slideToEvent":"stepper-64971a85e6a17-step-change","sliderName":"stepper-64971a85e6a17"}'
    style="--progress: 0; --step: 1; --total: 3;">
    <div class="stepper__pin js-stepper__pin">

      <div class="stepper__bg js-stepper__bg">
        <div class="stepper__outer js-stepper__outer">
          <div class="stepper__inner container">
            <div class="article-carousel__item">
              <div class="article-carousel__item__visual">

                <a href="https://www.yuh.com/en/yuhlearn/how-invest-trends-you-care-about/"
                  class="article-carousel__item__visual__link"
                  data-ga4-trigger-params='{"event":"yuhlearn_howto_teaser","link_to":"en\/yuhlearn\/how-invest-trends-you-care-about\/"}'></a>

                <a href="https://www.yuh.com/en/yuhlearn/how-invest-trends-you-care-about/"
                  class="button button--style--link button--size--large article-carousel__item__button"
                  data-ga4-trigger-params='{"event":"yuhlearn_howto_teaser","link_to":"en\/yuhlearn\/how-invest-trends-you-care-about\/"}'>
                </a>
              </div>
            </div>
            <div
              class="button button--style--primary-white button--size--default button--icon-only crosslinks__links__link__cta">
              <div class="button__inner">
                <div class="icon button__icon"><svg>
                    <use
                      xlink:href="" />
                  </svg></div>
              </div>
            </div>

          </div>
        </div>
      </div>



      <div class="download__bg">
        <div class="download__bg__inner">
          <picture class="">
            <source data-srcset="https://www.yuh.com/en/wp-content/themes/yuh/assets/images/404/bg-group-sm.svg"
              media="(max-width: 759px)" />
            <source data-srcset="https://www.yuh.com/en/wp-content/themes/yuh/assets/images/404/bg-group.svg"
              media="(min-width: 760px)" />
            <img class="lazyload download__bg__landscape" data-sizes="auto" data-srcset="" alt="Illustration"
              style="" />
          </picture>
          <picture class="">
            <img class="lazyload download__bg__item download__bg__item--left" data-sizes="auto"
              data-srcset="https://www.yuh.com/en/wp-content/themes/yuh/assets/images/404/illustration1.svg"
              alt="Illustration" style="" />
          </picture>
          <picture class="">
            <img class="lazyload download__bg__item download__bg__item--center" data-sizes="auto"
              data-srcset="https://www.yuh.com/en/wp-content/themes/yuh/assets/images/404/illustration3.svg"
              alt="Illustration" style="" />
          </picture>
          <picture class="">
            <img class="lazyload download__bg__item download__bg__item--right" data-sizes="auto"
              data-srcset="https://www.yuh.com/en/wp-content/themes/yuh/assets/images/404/illustration2.svg"
              alt="Illustration" style="" />
          </picture>
        </div>
      </div>
    </div>
  </div>
</div>
