import { Component } from "@angular/core";
import { PopupReceiveComponent } from "../popup-receive.component";

@Component({
    selector: "popup-receive-crypto",
    templateUrl: "popup-receive-crypto.component.html",
    styleUrls: [
        "../../../../styles/_general.scss",
		    "../../../../styles/_layout.scss",
		    "../../../../styles/_helpers.scss",
        "../../popup.component.scss",
        "../popup-receive.component.scss",
        "popup-receive-crypto.component.scss",
    ],
})
export class PopupReceiveCryptoComponent extends PopupReceiveComponent {}
