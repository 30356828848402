<div class="pay-wrapper">
<div class="module module--hero_app" data-name="hero_app" id="">
  <div class="hero-app  " data-component='{"component": "hero-app"}'>
    <div class="hero-base hero-base--2 hero-base--pink"></div>
    <div class="hero-base"></div>
    <div class="hero-general__back">
      <picture class="">
        <source data-srcset="https://www.yuh.com/en/wp-content/uploads/sites/2/2023/02/background-1-desktop.svg"
          media="--tablet" />
        <img class="lazyload hero-app__bg-illustration" data-sizes="auto"
          data-srcset="https://www.yuh.com/en/wp-content/uploads/sites/2/fly-images/1700/background-1-2096x9999.png"
          style="" />
      </picture>
    </div>
    <div class="hero-general__front">
      <div class="container hero-app__container">
        <div class="hero-app__content">
          <h1 class="h1 hero-app__content__headline" [innerHTML]="words['pay']?.['title']?.[lang.slug]"></h1>
          <div class="hero-app__content__description copy-lg">
            {{words['pay']?.['subtitle']?.[lang.slug]}}
            <br />
          </div>
          <a class="button button--style--link button--size--default hero-app__content__cta js-hero-app__content__cta"
            data-link="benefits" data-ga4-trigger-params='{"event":"appservices_header","link_to":"en\/app\/pay"}'>
            <div class="button__inner">
              <div class="icon button__icon button__icon--right"><svg xmlns="http://www.w3.org/2000/svg" width="24"
                  height="24" viewBox="0 0 24 24">
                  <path
                    d="M20.383 12.578a1.5 1.5 0 0 0-.323-1.64l-7-7a1.5 1.5 0 0 0-2.12 2.122l4.44 4.44H5a1.5 1.5 0 0 0 0 3h10.38l-4.44 4.44a1.5 1.5 0 0 0 2.084 2.16l.037-.037 7-7a1.5 1.5 0 0 0 .322-.485Z"
                    fill="currentColor" />
                </svg></div> <span class="button__label">{{words['pay']?.['discover-more']?.[lang.slug]}}</span>
            </div>
          </a>
        </div>
        <div class="hero-app__illustrations hero-app__illustrations--position-bottom">
          <div class="hero-app__illustrations__bg">
          </div>
          <div class="hero-app__illustrations__inner">

            <div class="image-animation hero-app__illustration hero-app__illustration--json">
              <div style="margin-left: 10rem" class="image-animation__img js-image-animation__img"><img src="assets/images/mob.png"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="module module--about_us module--space-small" data-name="about_us" id="">

  <div class="about-us " >
    <div class="about-us__container container">
      <h2 class="h1 about-us__text js-about-us__text">{{words['pay']?.['about-us-title']?.[lang.slug]}}</h2>
      <a (click)="pRegisterActive = true" class="button button--style--primary button--size--default">
        <div class="button__inner">
          <span class="button__label">{{words['pay']?.['create-free-acc']?.[lang.slug]}}</span>
        </div>
      </a>
    </div>
  </div>
</div>

<div
  class="module module--app-features js-content-navigation__section module--space-top-small module--space-bottom-medium module--bg-image"
  data-section-index="0" data-name="app-features" id="benefits">
  <div class="module__bg-image module__bg-image --position-top">
    <div class="module__bg-image__img --tablet" style="--src:url('sites/2/2023/06/yuh-bg-desktop-pink-04.pn')">
    </div>
    <div class="module__bg-image__img " style="--src:url('sites/2/2023/06/yuh-bg-mobile-pink-07.pn')"></div>
  </div>

  <div class="app-features__screens__inner js-app-features__screens__inner">
    <div class="app-features__screen js-app-features__screen common">
          <h2 class="h2 app-features__main-headline">{{words['pay']?.['online-payment']?.[lang.slug]}}<br /></h2>
      <div class="app-features__feature js-app-features__feature not-common" data-bg="">
        <div
          class="app-features__feature__image app-features__feature__image--mobile--center app-features__feature__image--desktop--center"
          style="aspect-ratio: auto">
          <img src="/assets/images/card1.png" class="card-image">
        </div>
        <div class="app-features__feature__content">
          <h3 class="h3 app-features__headline" [innerHTML]="words['pay']?.['free-can-be']?.[lang.slug]"><br />
          </h3>
          <p class="app-features__description copy-md">
            {{words['pay']?.['features-description']?.[lang.slug]}}
            <br />
          </p>
          <div class="app-features__benefits">
            <div class="app-features__benefit">
              <h4 class="h4 app-features__benefit__headline">{{words['pay']?.['free']?.[lang.slug]}}</h4>
              <p class="app-features__benefit__description copy-sm">
              <p>{{words['pay']?.['zero-cost']?.[lang.slug]}}</p>
            </div>
            <div class="app-features__benefit">
              <h4 class="h4 app-features__benefit__headline">{{words['pay']?.['no-hidden-fees']?.[lang.slug]}}</h4>
              <p class="app-features__benefit__description copy-sm">
              <p>{{words['pay']?.['transparent']?.[lang.slug]}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="module module--feature_small module--space-small module--bg-image" data-name="feature_small" id="">
  <div class="module__bg-image module__bg-image">
    <div class="module__bg-image__img --tablet" style="--src:url('sites/2/2023/06/yuh-bg-desktop-pink-02.png')"></div>
    <div class="module__bg-image__img " style="--src:url('sites/2/2023/06/yuh-bg-mobile-pink-04.png')"></div>
  </div>
  <div class="feature-small feature-small--pink">
    <div class="container feature-small__inner">
      <div class="feature-small__features">
        <div class="feature-small__features__feature">
          <div class="feature-small__features__feature__inner">
            <h4 class="feature-small__features__feature__inner__title">{{words['pay']?.['fs-title1']?.[lang.slug]}}</h4>
            <div class="feature-small__features__feature__inner__description copy-md color-grey">
              {{words['pay']?.['fs-text1']?.[lang.slug]}}<br />
            </div>
          </div>
        </div>
        <div class="feature-small__features__feature">
          <div class="feature-small__features__feature__inner">
            <h4 class="feature-small__features__feature__inner__title">{{words['pay']?.['fs-title2']?.[lang.slug]}}</h4>
            <div class="feature-small__features__feature__inner__description copy-md color-grey">
              {{words['pay']?.['fs-text2']?.[lang.slug]}}<br />
            </div>
          </div>
        </div>
        <div class="feature-small__features__feature">
          <div class="feature-small__features__feature__inner">
            <h4 class="feature-small__features__feature__inner__title">{{words['pay']?.['fs-title3']?.[lang.slug]}}</h4>
            <div class="feature-small__features__feature__inner__description copy-md color-grey">
              {{words['pay']?.['fs-text3']?.[lang.slug]}}<br />
            </div>
          </div>
        </div>
        <div class="feature-small__features__feature">
          <div class="feature-small__features__feature__inner">
            <h4 class="feature-small__features__feature__inner__title">{{words['pay']?.['fs-title4']?.[lang.slug]}}</h4>
            <div class="feature-small__features__feature__inner__description copy-md color-grey">
              {{words['pay']?.['fs-text4']?.[lang.slug]}}<br />
            </div>
          </div>
        </div>
      </div>
    </div>
    <a (click)="pRegisterActive = true"
      class="button button--style--primary button--size--default feature-small__cta"
      data-ga4-trigger-params='{"event":"create_free_account","link_to":"#app-download","placement":"advantages_teaser"}'>
      <div class="button__inner">
        <span class="button__label">{{words['pay']?.['create-free-acc']?.[lang.slug]}}</span>
      </div>
    </a>
  </div>
</div>


<div
  class="module module--image-text js-content-navigation__section module--space-top-small module--space-bottom-medium module--bg-image"
  data-section-index="1" data-name="image-text" id="Fees">
  <div class="module__bg-image module__bg-image">
    <div class="module__bg-image__img " style="--src:url('sites/2/2023/06/yuh-bg-mobile-pink-05.png')"></div>
  </div>
</div>

<!-- <div class="download__bg"> -->
<!--   <div class="download__bg__inner"> -->
<!--     <picture class=""> -->
<!--       <source data-srcset="https://www.yuh.com/en/wp-content/themes/yuh/assets/images/404/bg-group-sm.svg" -->
<!--         media="(max-width: 759px)" /> -->
<!--       <source data-srcset="https://www.yuh.com/en/wp-content/themes/yuh/assets/images/404/bg-group.svg" -->
<!--         media="(min-width: 760px)" /> -->
<!--       <img class="lazyload download__bg__landscape" data-sizes="auto" data-srcset="" alt="Illustration" style="" /> -->
<!--     </picture> -->
<!--     <picture class=""> -->
<!--       <img class="lazyload download__bg__item download__bg__item--left" data-sizes="auto" -->
<!--         data-srcset="https://www.yuh.com/en/wp-content/themes/yuh/assets/images/404/illustration1.svg" -->
<!--         alt="Illustration" style="" /> -->
<!--     </picture> -->
<!--     <picture class=""> -->
<!--       <img class="lazyload download__bg__item download__bg__item--center" data-sizes="auto" -->
<!--         data-srcset="https://www.yuh.com/en/wp-content/themes/yuh/assets/images/404/illustration3.svg" -->
<!--         alt="Illustration" style="" /> -->
<!--     </picture> -->
<!--     <picture class=""> -->
<!--       <img class="lazyload download__bg__item download__bg__item--right" data-sizes="auto" -->
<!--         data-srcset="https://www.yuh.com/en/wp-content/themes/yuh/assets/images/404/illustration2.svg" -->
<!--         alt="Illustration" style="" /> -->
<!--     </picture> -->
<!--   </div> -->
<!-- </div> -->

<div class="module module--stepper js-content-navigation__section module--space-big module--bg-image"
  data-section-index="3" data-name="stepper" id="Get started">
  <div class="module__bg-image module__bg-image">
    <div class="module__bg-image__img --tablet" style="--src:url('sites/2/2023/06/yuh-bg-desktop-purple-05.png')">
    </div>
    <div class="module__bg-image__img " style="--src:url('sites/2/2023/06/yuh-bg-mobile-purple-05.png')"></div>
  </div>

  <div class="stepper  stepper--scrolltrigger" data-component='{ "component": "stepper" }'
    data-settings='{"scrollTrigger":true,"slideToEvent":"stepper-64971a85e6a17-step-change","sliderName":"stepper-64971a85e6a17"}'
    style="--progress: 0; --step: 1; --total: 3;">
    <div class="stepper__pin js-stepper__pin">

      <div class="stepper__bg js-stepper__bg">
        <div class="stepper__outer js-stepper__outer">
          <div class="stepper__inner container">
            <div class="stepper__header js-stepper__header"></div>
            <div class="article-carousel__item">
              <div class="article-carousel__item__visual">

                <a href="#"
                  class="article-carousel__item__visual__link"
                  data-ga4-trigger-params='{"event":"yuhlearn_howto_teaser","link_to":"en\/yuhlearn\/how-invest-trends-you-care-about\/"}'></a>

                <a href="#"
                  class="button button--style--link button--size--large article-carousel__item__button"
                  data-ga4-trigger-params='{"event":"yuhlearn_howto_teaser","link_to":"en\/yuhlearn\/how-invest-trends-you-care-about\/"}'>
                </a>
              </div>
            </div>
            <div
              class="button button--style--primary-white button--size--default button--icon-only crosslinks__links__link__cta">
              <div class="button__inner">
                <div class="icon button__icon"><svg>
                    <use
                      xlink:href="" />
                  </svg></div>
              </div>
            </div>

          </div>
        </div>
      </div>



      <div class="download__bg">
        <div class="download__bg__inner">
          <picture class="">
            <source data-srcset="https://www.yuh.com/en/wp-content/themes/yuh/assets/images/404/bg-group-sm.svg"
              media="(max-width: 759px)" />
            <source data-srcset="https://www.yuh.com/en/wp-content/themes/yuh/assets/images/404/bg-group.svg"
              media="(min-width: 760px)" />
            <img class="lazyload download__bg__landscape" data-sizes="auto" data-srcset="" alt="Illustration"
              style="" />
          </picture>
          <picture class="">
            <img class="lazyload download__bg__item download__bg__item--left" data-sizes="auto"
              data-srcset="https://www.yuh.com/en/wp-content/themes/yuh/assets/images/404/illustration1.svg"
              alt="Illustration" style="" />
          </picture>
          <picture class="">
            <img class="lazyload download__bg__item download__bg__item--center" data-sizes="auto"
              data-srcset="https://www.yuh.com/en/wp-content/themes/yuh/assets/images/404/illustration3.svg"
              alt="Illustration" style="" />
          </picture>
          <picture class="">
            <img class="lazyload download__bg__item download__bg__item--right" data-sizes="auto"
              data-srcset="https://www.yuh.com/en/wp-content/themes/yuh/assets/images/404/illustration2.svg"
              alt="Illustration" style="" />
          </picture>
        </div>
      </div>
    </div>
  </div>
</div>
</div>
