import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from '@angular/forms';
import { RouterModule } from "@angular/router";
import { ComponentsModule } from "src/app/common/components/components.module";
import { PayPage } from "./pages/pay.page";

let routes = RouterModule.forChild ([            
	{path: "", component: PayPage},	
]);

@NgModule({	
  imports: [	
		CommonModule,
		RouterModule,
    FormsModule,
		ComponentsModule,
		routes,
	],
	declarations: [
		PayPage,
	],    		    
})
export class PayModule {}
