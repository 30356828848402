import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AppService } from "src/app/common/services/app.service";
import { AuthService } from "src/app/common/services/auth.service";
import { PageRepository } from "src/app/common/services/repositories/page.repository";
import { SimplePage } from "../../simple.page";

@Component({
    selector: "save-page",
    templateUrl: "save.page.html",
    styleUrls: ["save.page.scss"],
})
export class SavePage extends SimplePage implements OnInit {
    constructor(
        protected appService: AppService,
        protected authService: AuthService,
        protected pageRepository: PageRepository,
        protected route: ActivatedRoute,
        protected router: Router,
    ) 
    {
        super(appService, pageRepository, route, router);
    }

    // auth
    set pRegisterActive(v: boolean) {this.appService.pRegisterActive = v;}
    set pLoginActive(v: boolean) {this.appService.pLoginActive = v;}

    public async ngOnInit(): Promise<void> {
        this.initScroll(); 
        await this.initPage('save');        
        this.route.params.subscribe(p => this.initSEO());  
    }

    public scrollTo(blockName: string): void {
        this.appService.scrollTo(blockName);
    }
}
