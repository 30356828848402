<div class="module module--download module--space-big" data-name="download" id="">
  <div class="download">
    <div class="download__inner container">
      <div class="download__content">

        <div class="download__buttons">
          <a href="#" title="App Store"
            target="_blank" data-app="app-appstore"
            data-ga4-trigger-params='{"event":"download_app","link_to":"https:\/\/apps.apple.com\/ch\/app\/yuh-3-in-1-finance-app\/id1493935010?l=en"}'>

          </a>
          <a href="#" title="GooglePlay" target="_blank"
            data-app="app-googleplay"
            data-ga4-trigger-params='{"event":"download_app","link_to":"https:\/\/play.google.com\/store\/apps\/details?id=com.yuh"}'>

          </a>
          <a href="#"
            title="App Gallery" target="_blank" data-app="app-huawei"
            data-ga4-trigger-params='{"event":"download_app","link_to":"https:\/\/appgallery.huawei.com\/#\/app\/C104174511?sharePrepath=ag&#038;locale=de_DE&#038;source=appshare&#038;subsource=C104174511"}'>

          </a>
        </div>
      </div>
      <div class="download__bg">
        <div class="download__bg__inner">
          <picture class="">
            <source data-srcset="https://www.yuh.com/en/wp-content/themes/yuh/assets/images/404/bg-group-sm.svg"
              media="(max-width: 759px)" />
            <source data-srcset="https://www.yuh.com/en/wp-content/themes/yuh/assets/images/404/bg-group.svg"
              media="(min-width: 760px)" />
            <img class="lazyload download__bg__landscape" data-sizes="auto" data-srcset="" alt="Illustration"
              style="" />
          </picture>
          <picture class="">
            <img class="lazyload download__bg__item download__bg__item--left" data-sizes="auto"
              data-srcset="https://www.yuh.com/en/wp-content/themes/yuh/assets/images/404/illustration1.svg"
              alt="Illustration" style="" />
          </picture>
          <picture class="">
            <img class="lazyload download__bg__item download__bg__item--center" data-sizes="auto"
              data-srcset="https://www.yuh.com/en/wp-content/themes/yuh/assets/images/404/illustration3.svg"
              alt="Illustration" style="" />
          </picture>
          <picture class="">
            <img class="lazyload download__bg__item download__bg__item--right" data-sizes="auto"
              data-srcset="https://www.yuh.com/en/wp-content/themes/yuh/assets/images/404/illustration2.svg"
              alt="Illustration" style="" />
          </picture>
        </div>
      </div>
    </div>
  </div>
</div>
