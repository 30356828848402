<div class="module module--services module--space-big module--bg-image" data-name="services" id="">
  <div class="module__bg-image module__bg-image --position-top">
    <div class="module__bg-image__img --tablet"
      style="--src:url('wp-content/uploads/sites/2/2023/06/yuh-bg-desktop-purple-01.png')"></div>
    <div class="module__bg-image__img "
      style="--src:url('wp-content/uploads/sites/2/2023/06/yuh-bg-mobile-purple-07.png')"></div>
  </div>
  <div class="services " data-component='{ "component": "services" }'>
    <div class="services__pin js-services__pin">
      <div class="services__bg js-services__bg">
        <div class="services__content">
          <div class="services__textblock container js-services__header">
            <div class="services__textblock__inner">
              <h2 class="h2">{{words['home']?.['services-title']?.[lang.slug]}}<br />
              </h2>
              <p class="copy-lg">
              <p
                data-pm-slice="1 1 [&quot;table&quot;,{&quot;isNumberColumnEnabled&quot;:false,&quot;layout&quot;:&quot;default&quot;,&quot;__autoSize&quot;:false,&quot;localId&quot;:&quot;1ddab185-e5d1-4ab0-927d-90c0b4c17ea9&quot;},&quot;tableRow&quot;,null,&quot;tableCell&quot;,{&quot;colspan&quot;:1,&quot;rowspan&quot;:1,&quot;colwidth&quot;:null,&quot;background&quot;:null}]">
                {{words['home']?.['services-text']?.[lang.slug]}}
                <br />
              </p>
            </div>
          </div>
          <div class="services__outer">
            <div class="services__inner container">
              <div class="services__items js-services__items">
                <div class="services__items__inner js-services__items__inner" data-component='{ "component": "slider" }'
                  data-slider-settings='{"from":"tablet","slidesPerView":2,"spaceBetween":16,"autoHeight":false,"slideToClickedHiddenSlide":true,"navigationWrapper":true,"navigation":true,"breakpoints":{"tablet":{"slidesPerView":2},"desktop":{"slidesPerView":3}}}'>
                  <div style="--order: 3; --index: 0" class="services__item js-services__item services__item--default">
                    <a routerLink="/{{lang.slug}}/pay"
                      data-ga4-trigger-params='{"event":"carousel_services","link_to":"en\/app\/pay\/"}'
                      class="services__item__inner js-services__item__inner bg-color-pink">
                      <div class="services__item__content">
                        <picture class="services__item__img">
                          <img class="lazyload " data-sizes="auto"
                            data-srcset="wp-content/themes/yuh/assets/images/hero-general/mo1.png" alt="phone screen"
                            style="" />
                        </picture>
                        <div class="services__item__text">
                          <h3 class="h4">{{words['home']?.['services-pay-title']?.[lang.slug]}}</h3>
                          <p class="copy-md">{{words['home']?.['services-pay-text']?.[lang.slug]}}<br />
                          </p>
                        </div>
                      </div>
                      <div class="services__item__button">
                        <div class="button button--style--primary-white button--size--default button--icon-only">
                          <div class="button__inner">
                            <div class="icon button__icon"><svg>
                                <use
                                  xlink:href="" />
                              </svg></div>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div style="--order: 2; --index: 1" class="services__item js-services__item services__item--default">
                    <a routerLink="/{{lang.slug}}/save"
                      data-ga4-trigger-params='{"event":"carousel_services","link_to":"en\/app\/save\/"}'
                      class="services__item__inner js-services__item__inner bg-color-light-purple">
                      <div class="services__item__content">
                        <picture class="services__item__img">
                          <img class="lazyload " data-sizes="auto"
                            data-srcset="wp-content/themes/yuh/assets/images/hero-general/mo2.png" alt="phone screen"
                            style="" />
                        </picture>
                        <div class="services__item__text">
                          <h3 class="h4">{{words['home']?.['services-save-title']?.[lang.slug]}}</h3>
                          <p class="copy-md">{{words['home']?.['services-save-text']?.[lang.slug]}}<br />
                          </p>
                        </div>
                      </div>
                      <div class="services__item__button">
                        <div class="button button--style--primary-white button--size--default button--icon-only">
                          <div class="button__inner">
                            <div class="icon button__icon"><svg>
                                <use
                                  xlink:href="" />
                              </svg></div>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div style="--order: 1; --index: 2" class="services__item js-services__item services__item--default">
                    <a routerLink="/{{lang.slug}}/invest"
                      data-ga4-trigger-params='{"event":"carousel_services","link_to":"en\/app\/invest\/"}'
                      class="services__item__inner js-services__item__inner bg-color-purple">
                      <div class="services__item__content">
                        <picture class="services__item__img">
                          <img class="lazyload " data-sizes="auto"
                            data-srcset="wp-content/themes/yuh/assets/images/hero-general/mo3.png" alt="phone screen"
                            style="" />
                        </picture>
                        <div class="services__item__text">
                          <h3 class="h4">{{words['home']?.['services-invest-title']?.[lang.slug]}}</h3>
                          <p class="copy-md">{{words['home']?.['services-invest-text']?.[lang.slug]}}<br />
                          </p>
                        </div>
                      </div>
                      <div class="services__item__button">
                        <div class="button button--style--primary-white button--size--default button--icon-only">
                          <div class="button__inner">
                            <div class="icon button__icon"><svg>
                                <use
                                  xlink:href="" />
                              </svg></div>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
