import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './common/services/guards/auth.guard';
import { AccountModule } from './pages/account/account.module';
import { ErrorsModule } from './pages/errors/errors.module';
import { HomePage } from './pages/home/pages/home.page';
import { AboutModule } from './pages/about/about.module';
import { StaticModule } from './pages/static/static.module';
import { InvestModule } from './pages/invest/invest.module';
import { PayModule } from './pages/pay/pay.module';
import { SaveModule } from './pages/save/save.module';

const routes: Routes = [
    {path: "", component: HomePage, data: {mark: "home"}}, // mark for reuse
    {path: "ru", pathMatch: "full", redirectTo: "/"},
    {path: ":lang", component: HomePage, data: {mark: "home"}},  // mark for reuse
    {path: ":lang/about", loadChildren: () => AboutModule, data: {mark: "about"} },  
    {path: ":lang/invest", loadChildren: () => InvestModule, data: {mark: "invest"} },  
    {path: ":lang/pay", loadChildren: () => PayModule, data: {mark: "pay"} },  
    {path: ":lang/save", loadChildren: () => SaveModule, data: {mark: "save"} },  
    {path: ":lang/errors", loadChildren: () => ErrorsModule}, 
    {path: ":lang/account", loadChildren: () => AccountModule, canActivate: [AuthGuard]},  
    {path: ":lang/:page", loadChildren: () => StaticModule},  
    {path: "**", redirectTo: "/ru/errors/404"}
];


@NgModule({
    // imports: [RouterModule.forRoot(routes, {initialNavigation: 'enabledBlocking', })],
    imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
    exports: [RouterModule],
})
export class AppRoutingModule { }
