import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from '@angular/forms';
import { RouterModule } from "@angular/router";
import { ComponentsModule } from "src/app/common/components/components.module";
import { SavePage } from "./pages/save.page";

let routes = RouterModule.forChild ([            
	{path: "", component: SavePage},	
]);

@NgModule({	
  imports: [	
		CommonModule,
		RouterModule,
    FormsModule,
		ComponentsModule,
		routes,
	],
	declarations: [
		SavePage,
	],    		    
})
export class SaveModule {}
