<div class="module module--about_us module--space-small module--bg-image" data-name="about_us" id="">
  <div class="module__bg-image module__bg-image">
    <div class="module__bg-image__img --tablet" style="--src:url('')">
    </div>
    <div class="module__bg-image__img " style="--src:url('sites/2/2023/06/yuh-bg-mobile-purple-02.png')"></div>
  </div>

  <div class="about-us">
    <div class="about-us__container container">
      <h2 class="h1 about-us__text js-about-us__text">{{words['invest']?.['about-us-text']?.[lang.slug]}}</h2>
      <a href="index.html#app-download" target="_self" class="button button--style--primary button--size--default"
        data-ga4-trigger-params='{"event":"create_free_account","link_to":"#app-download","placement":"beforeyuh_teaser"}'>
        <div class="button__inner">
          <span class="button__label">{{words['invest']?.['create-account']?.[lang.slug]}}</span>
        </div>
      </a>
    </div>
  </div>

</div>
