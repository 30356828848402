<div
  class="module module--app-features js-content-navigation__section module--space-top-small module--space-bottom-medium module--bg-image"
  data-section-index="0" data-name="app-features" id="Benefits">
  <div class="module__bg-image module__bg-image">
    <div class="module__bg-image__img --tablet" style="--src:url('sites/2/2023/06/yuh-bg-desktop-purple-04.png')">
    </div>
    <div class="module__bg-image__img " style="--src:url('sites/2/2023/06/yuh-bg-mobile-purple-03.png')"></div>
  </div>

  <div class="app-features  js-app-features"
    style="--progress: 0; --step: 3; --total: 3;">
    <div class="app-features__pin js-app-features__pin">
      <div class="app-features__screens js-app-features__screens">

        <div class="app-features__inner container js-app-features__header">
          <div class="app-features__top">
            <h2 class="h2 app-features__main-headline">{{words['invest']?.['features-title']?.[lang.slug]}}<br />
            </h2>
          </div>
        </div>

        <div class="app-features__screens__inner js-app-features__screens__inner">
          <div class="app-features__screen js-app-features__screen">
            <div class="app-features__feature js-app-features__feature   " data-bg="">

              <div
                class="app-features__feature__image app-features__feature__image--mobile--center app-features__feature__image--desktop--center">
                <picture class="">
                  <source data-srcset="assets/images/invest.png" />
                  <img class="lazyload app-features__image" data-sizes="auto" data-srcset="" style="" />
                </picture>
              </div>
              <div class="app-features__feature__content">
                <h3 class="h3 app-features__headline">{{words['invest']?.['toss-coin']?.[lang.slug]}}<br />
                </h3>
                <p class="app-features__description copy-md">
                  {{words['invest']?.['digital-assets']?.[lang.slug]}}<br />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
