import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AppService } from "src/app/common/services/app.service";
import { AuthService } from "src/app/common/services/auth.service";
import { ILang } from "src/app/model/entities/lang.interface";
import { ISettings } from "src/app/model/entities/settings.interface";
import { IWords } from "src/app/model/entities/words.interface";

@Component({
    selector: "the-hero",
    templateUrl: "hero.component.html",
    styleUrls: ["hero.component.scss"],
})
export class HeroComponent implements OnInit {
    constructor(
        protected appService: AppService,
        protected authService: AuthService,
        protected router: Router,
    ) {}

    get words(): IWords {return this.appService.words;}
    get lang(): ILang {return this.appService.lang;}
	  get settings(): ISettings {return this.appService.settings;}
    get authenticated(): boolean {return this.authService.authData !== null;}

	  // auth
    set pRegisterActive(v: boolean) {this.appService.pRegisterActive = v;}
    set pLoginActive(v: boolean) {this.appService.pLoginActive = v;}

    public ngOnInit(): void {}
}
