import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from '@angular/forms';
import { RouterModule } from "@angular/router";
import { ComponentsModule } from "src/app/common/components/components.module";
import { DownloadComponent } from "./components/download/download.component";
import { FaqComponent } from "./components/faq/faq.component";
import { HeroComponent } from "./components/hero/hero.component";
import { ImageTextComponent } from "./components/image-text/image-text.component";
import { ListPaysystemsComponent } from "./components/list-paysystems/list-paysystems.component";
import { SecurityComponent } from "./components/security/security.component";
import { ServicesComponent } from "./components/services/services.component";
import { HomePage } from "./pages/home.page";

@NgModule({	
    imports: [	
		CommonModule,
		RouterModule,
    FormsModule,
		ComponentsModule,
	],
	declarations: [
		HomePage,
		ListPaysystemsComponent,
		HeroComponent,
		ServicesComponent,
		SecurityComponent,
		ImageTextComponent,
		FaqComponent,
		DownloadComponent,
	],    		    
})
export class HomeModule {}
