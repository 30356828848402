<div class="module module--feature_small module--space-small module--bg-image" data-name="feature_small" id="">
  <div class="module__bg-image module__bg-image">
    <div class="module__bg-image__img --tablet" style="--src:url('sites/2/2023/06/yuh-bg-desktop-purple-02.png')">
    </div>
    <div class="module__bg-image__img " style="--src:url('sites/2/2023/06/yuh-bg-mobile-purple-06.png')"></div>
  </div>
  <div class="feature-small feature-small--purple">
    <div class="container feature-small__inner">
      <div class="feature-small__features">
        <div class="feature-small__features__feature">
          <div class="feature-small__features__feature__inner">
            <h4 class="feature-small__features__feature__inner__title">
              {{words['invest']?.['feature-small-title1']?.[lang.slug]}}
              </h4>
            <div class="feature-small__features__feature__inner__description copy-md color-grey">
              {{words['invest']?.['feature-small-text1']?.[lang.slug]}}
              <br />
            </div>
          </div>
        </div>
        <div class="feature-small__features__feature">
          <div class="feature-small__features__feature__inner">
            <h4 class="feature-small__features__feature__inner__title">
              {{words['invest']?.['feature-small-title1']?.[lang.slug]}}
              </h4>
            <div class="feature-small__features__feature__inner__description copy-md color-grey">
              {{words['invest']?.['feature-small-text1']?.[lang.slug]}}
              <br />
            </div>
          </div>
        </div>
      </div>
    </div>
    <a href="index.html#app-download" target="_self"
      class="button button--style--primary button--size--default feature-small__cta"
      data-ga4-trigger-params='{"event":"create_free_account","link_to":"#app-download","placement":"advantages_teaser"}'>
      <div class="button__inner">
        <span class="button__label">{{words['invest']?.['create-free-acc']?.[lang.slug]}}</span>
      </div>
    </a>
  </div>
</div>
