import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AppService } from "src/app/common/services/app.service";
import { AuthService } from "src/app/common/services/auth.service";
import { ILang } from "src/app/model/entities/lang.interface";
import { ISettings } from "src/app/model/entities/settings.interface";
import { IWords } from "src/app/model/entities/words.interface";

@Component({
    selector: "the-features",
    templateUrl: "features.component.html",
    styleUrls: ["features.component.scss"],
})
export class FeaturesComponent implements OnInit {
    constructor(
        protected appService: AppService,
        protected authService: AuthService,
        protected router: Router,
    ) {}

    get words(): IWords {return this.appService.words;}      
    get lang(): ILang {return this.appService.lang;}  
	  get settings(): ISettings {return this.appService.settings;}

	  // auth
    set pRegisterActive(v: boolean) {this.appService.pRegisterActive = v;}

    public ngOnInit(): void {}
}
