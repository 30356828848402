<div class="module module--article-text module--space-small module--bg-image" data-name="article-text"
  id="yuhmans_text__link">
  <div class="module__bg-image module__bg-image --position-buttom">
    <div class="module__bg-image__img --tablet"
      style="--src:url('uploads/sites/2/2023/06/yuh-bg-desktop-purple-02.png')"></div>
    <div class="module__bg-image__img " style="--src:url('uploads/sites/2/2023/06/yuh-bg-mobile-purple-02.png')">
    </div>
  </div>

  <div class="article-text">
    <div class="container">
      <div class="article-text__inner">
        <div class="article-text__content">
          <div class="article-text__block article-text__block--title">
            <div class="text-style text-style--title ">
              <h4 class="h4">{{words['about']?.['article-title']?.[lang.slug]}}<br />
              </h4>
            </div>
          </div>
          <div class="article-text__block article-text__block--paragraph">
            <div class="text-style text-style--paragraph ">
              <div class="text-style__text  copy-md color-grey">{{words['about']?.['article-text']?.[lang.slug]}}<br />
                <!-- <a href='yuhmans/index.html'>Learn more</a> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
