<div class="footer module--space-top-small" #footer name="footer">
  <div class="footer__outer container">
    <div class="footer__inner">
      <div class="footer__newsletter" data-component='{ "component": "newsletter-popup" }'>
        <div class="footer__newsletter__download">
          <h3 class="label-xs">{{words['footer']?.['get-started']?.[lang.slug]}}</h3>
          <h4 class="h5">
            <p>{{words['footer']?.['sign-up']?.[lang.slug]}}</p>
          </h4>
          <a (click)="pRegisterActive = true" class="button button--style--secondary button--size--default footer__newsletter__download__button"
            data-ga4-trigger-params='{"event":"download_app_footer"}'>
            <div class="button__inner">
              <span class="button__label">{{words['footer']?.['create-account']?.[lang.slug]}}</span>
            </div>
          </a>
        </div>

        <div class="footer__social">
          <h3 class="label-xs">{{words['footer']?.['follow-rdd']?.[lang.slug]}}</h3>
          <ul class="footer__social__items-custom" style="margin-bottom: 1rem">
            <li>
              <a [href]="words['contacts']?.['phone']?.[lang.slug]" class="footer__social__item" title="Twitter" target="_blank">
                <div class="icon ">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="#000000" width="800px" height="800px" viewBox="0 0 32 32"><path d="M11.748 5.773S11.418 5 10.914 5c-.496 0-.754.229-.926.387S6.938 7.91 6.938 7.91s-.837.731-.773 2.106c.054 1.375.323 3.332 1.719 6.058 1.386 2.72 4.855 6.876 7.047 8.337 0 0 2.031 1.558 3.921 2.191.549.173 1.647.398 1.903.398.26 0 .719 0 1.246-.385.536-.389 3.543-2.807 3.543-2.807s.736-.665-.119-1.438c-.859-.773-3.467-2.492-4.025-2.944-.559-.459-1.355-.257-1.699.054-.343.313-.956.828-1.031.893-.112.086-.419.365-.763.226-.438-.173-2.234-1.148-3.899-3.426-1.655-2.276-1.837-3.02-2.084-3.824a.56.56 0 0 1 .225-.657c.248-.172 1.161-.933 1.161-.933s.591-.583.344-1.27-1.906-4.716-1.906-4.716z"/></svg>
                </div>
                <div>{{words['contacts']?.['phone']?.[lang.slug]}}</div>
              </a>
            </li>
            <li>
              <a [href]="words['contacts']?.['email']?.[lang.slug]" class="footer__social__item" title="Twitter" target="_blank">
                <div class="icon ">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" id="mail">
                    <path fill="#231f20" d="M16,14.81,28.78,6.6A3,3,0,0,0,27,6H5a3,3,0,0,0-1.78.6Z"></path>
                    <path fill="#231f20"
                      d="M16.54,16.84h0l-.17.08-.08,0A1,1,0,0,1,16,17h0a1,1,0,0,1-.25,0l-.08,0-.17-.08h0L2.1,8.26A3,3,0,0,0,2,9V23a3,3,0,0,0,3,3H27a3,3,0,0,0,3-3V9a3,3,0,0,0-.1-.74Z">
                    </path>
                  </svg>
                </div>
                <div>{{words['contacts']?.['email']?.[lang.slug]}}</div>
              </a>
            </li>
          </ul>

          <ul class="footer__social__items footer__social__items-hor">
            <li>
              <a [href]="words['contacts']?.['tg']?.[lang.slug]" class="footer__social__item" title="Twitter" target="_blank">
                <div class="icon ">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="telegram">
                    <path
                      d="m12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12c0-6.627-5.373-12-12-12zm5.894 8.221-1.97 9.28c-.145.658-.537.818-1.084.508l-3-2.21-1.446 1.394c-.14.18-.357.295-.6.295-.002 0-.003 0-.005 0l.213-3.054 5.56-5.022c.24-.213-.054-.334-.373-.121l-6.869 4.326-2.96-.924c-.64-.203-.658-.64.135-.954l11.566-4.458c.538-.196 1.006.128.832.941z">
                    </path>
                  </svg>
                </div>
              </a>
            <li>
              <a [href]="words['contacts']?.['wa']?.[lang.slug]" class="footer__social__item" title="Twitter" target="_blank">
                <div class="icon ">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" id="whatsapp">
                    <path
                      d="M8.002 0h-.004C3.587 0 0 3.588 0 8a7.94 7.94 0 0 0 1.523 4.689l-.997 2.972 3.075-.983A7.93 7.93 0 0 0 8.002 16C12.413 16 16 12.411 16 8s-3.587-8-7.998-8zm4.655 11.297c-.193.545-.959.997-1.57 1.129-.418.089-.964.16-2.802-.602-2.351-.974-3.865-3.363-3.983-3.518-.113-.155-.95-1.265-.95-2.413s.583-1.707.818-1.947c.193-.197.512-.287.818-.287.099 0 .188.005.268.009.235.01.353.024.508.395.193.465.663 1.613.719 1.731.057.118.114.278.034.433-.075.16-.141.231-.259.367-.118.136-.23.24-.348.386-.108.127-.23.263-.094.498.136.23.606.997 1.298 1.613.893.795 1.617 1.049 1.876 1.157.193.08.423.061.564-.089.179-.193.4-.513.625-.828.16-.226.362-.254.574-.174.216.075 1.359.64 1.594.757.235.118.39.174.447.273.056.099.056.564-.137 1.11z">
                    </path>
                  </svg>
                </div>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <nav class="footer__nav">
        <ul class="footer__nav__items">
          <li class="footer__nav__item">
            <h1 class="label-xs">{{words['footer']?.['rdd-app']?.[lang.slug]}}</h1>
            <div class="footer__nav__sub footer__nav__sub-custom">
              <ul class="footer__nav__subitems nav_item">
                <!-- <li class="footer__nav__subitem h6"> -->
                <!--   <a class="footer__nav__sublink" href="app/how-it-works/index.html" -->
                <!--     data-ga4-trigger-params='{"event":"footer","link_to":"en\/app\/how-it-works\/"}'> -->
                <!--   </a> -->
                <!-- </li> -->
                <li class="footer__nav__subitem h6">
                  <a class="footer__nav__sublink" routerLink="/{{lang.slug}}/pay"
                    data-ga4-trigger-params='{"event":"footer","link_to":"en\/app\/pay\/"}'>
                    {{words['header']?.['menu-pay']?.[lang.slug]}}</a>
                </li>
                <li class="footer__nav__subitem h6">
                  <a class="footer__nav__sublink" routerLink="/{{lang.slug}}/save"
                    data-ga4-trigger-params='{"event":"footer","link_to":"en\/app\/save\/"}'>
                    {{words['header']?.['menu-save']?.[lang.slug]}}</a>
                </li>
                <li class="footer__nav__subitem h6">
                  <a class="footer__nav__sublink" routerLink="/{{lang.slug}}/invest"
                    data-ga4-trigger-params='{"event":"footer","link_to":"en\/app\/invest\/"}'>
                    {{words['header']?.['menu-invest']?.[lang.slug]}}</a>
                </li>
                <li class="footer__nav__subitem h6">
                  <a class="footer__nav__sublink"  (click)="pCreditOrderActive = true"
                    data-ga4-trigger-params='{"event":"footer","link_to":"en\/app\/swissqoins\/"}'>
                    {{words['header']?.['menu-credits']?.[lang.slug]}}</a>

                  <div class="footer__logo">
                    <div class="icon icon--yuh"><svg>
                        <use
                          xlink:href="#" />
                      </svg></div>
                  </div>

                </li>
              </ul>
              <img class="nav_item" src="/assets/images/big-logo2.png">
            </div>

            <nav class="footer__legal footer_links">
              <!-- <ul class="footer__legal__items"> -->
              <!--   <li class="footer__legal__item"> -->
              <!--     <a class rite="footer__legal__link label-xs" href="imprint/index.html" target=""> -->
              <!--       Privacy policy -->
              <!--     </a> -->
              <!--   </li> -->
              <!--   <li class="footer__legal__item"> -->
              <!--     <a class rite="footer__legal__link label-xs" href="legal/index.html" target=""> -->
              <!--       Privacy policy 2 -->
              <!--     </a> -->
              <!--   </li> -->
              <!--   <li class="footer__legal__item"> -->
              <!--     <a class rite="footer__legal__link label-xs" href="index.html#cookie-settings" target=""> -->
              <!--       Privacy policy 3 </a> -->
              <!--   </li> -->
              <!-- </ul> -->
              <p class="footer__copyright label-xs">
                <a class rite="footer__legal__link label-xs" href="{{staticUrl}}/ufiles/{{ufiles?.['fees'].fileurl}}" target="_blank">
                  {{words['footer']?.['privacy']?.[lang.slug]}}
                </a>
              </p>
              <p class="footer__copyright label-xs">
                <a class rite="footer__legal__link label-xs" href="{{staticUrl}}/ufiles/{{ufiles?.['terms'].fileurl}}" target="_blank">
                  {{words['footer']?.['terms']?.[lang.slug]}}
                </a>
              </p>
              <p class="footer__copyright label-xs">{{words['footer']?.['copy']?.[lang.slug]}}</p>
            </nav>
