<div class="module module--hero_app" data-name="hero_app" id="">
  <div class="hero-app  " data-component='{"component": "hero-app"}'>
    <div class="hero-base hero-base--2 hero-base--light_purple"></div>
    <div class="hero-base"></div>
    <div class="hero-general__back">
      <picture class="">
        <source data-srcset="https://www.yuh.com/en/wp-content/uploads/sites/2/2023/02/background-1-desktop.svg"
          media="--tablet" />
        <img class="lazyload hero-app__bg-illustration" data-sizes="auto"
          data-srcset="/Users/djon/Desktop/SiteRDD/Invest/image/mo2.png " style="" />
      </picture>
    </div>
    <div class="hero-general__front">
      <div class="container hero-app__container">
        <div class="hero-app__content">
          <h1 class="h1 hero-app__content__headline" [innerHTML]="words['save']?.['title']?.[lang.slug]"><br />
          </h1>
          <div class="hero-app__content__description copy-lg">
            {{words['save']?.['subtitle']?.[lang.slug]}}
            <br />
          </div>
          <a class="button button--style--link button--size--default hero-app__content__cta js-hero-app__content__cta"
            data-link="Benefits" data-ga4-trigger-params='{"event":"appservices_header","link_to":"en\/app\/save"}'>
            <div class="button__inner">
              <div class="icon button__icon button__icon--right"><svg xmlns="http://www.w3.org/2000/svg" width="24"
                  height="24" viewBox="0 0 24 24">
                  <path
                    d="M20.383 12.578a1.5 1.5 0 0 0-.323-1.64l-7-7a1.5 1.5 0 0 0-2.12 2.122l4.44 4.44H5a1.5 1.5 0 0 0 0 3h10.38l-4.44 4.44a1.5 1.5 0 0 0 2.084 2.16l.037-.037 7-7a1.5 1.5 0 0 0 .322-.485Z"
                    fill="currentColor" />
                </svg></div> <span class="button__label">{{words['save']?.['discover']?.[lang.slug]}}</span>
            </div>
          </a>
        </div>
        <div class="hero-app__illustrations hero-app__illustrations--position-bottom">
          <div class="hero-app__illustrations__bg">
          </div>
          <div class="hero-app__illustrations__inner">

            <div class="image-animation hero-app__illustration hero-app__illustration--json">
              <div style="margin-left: 10rem" class="image-animation__img js-image-animation__img"><img
                  src="assets/images/mob.png"></div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="module module--about_us module--space-small module--bg-image" data-name="about_us" id="">
  <div class="module__bg-image module__bg-image">
    <div class="module__bg-image__img --tablet" style="--src:url('')">
    </div>
    <div class="module__bg-image__img " style="--src:url('sites/2/2023/06/yuh-bg-mobile-lightpurple-05.png')">
    </div>
  </div>

  <div class="about-us ">
    <div class="about-us__container container">
      <h2 class="h1 about-us__text js-about-us__text">{{words['save']?.['about-us']?.[lang.slug]}}</h2>
      <a (click)="pRegisterActive = true" class="button button--style--primary button--size--default"
        data-ga4-trigger-params='{"event":"create_free_account","link_to":"#app-download","placement":"beforeyuh_teaser"}'>
        <div class="button__inner">
          <span class="button__label">{{words['save']?.['create-free-acc']?.[lang.slug]}}</span>
        </div>
      </a>
    </div>
  </div>

</div>


<div
  class="module module--app-features js-content-navigation__section module--space-top-small module--space-bottom-medium module--bg-image"
  data-section-index="0" data-name="app-features" id="Benefits">
  <div class="module__bg-image module__bg-image">
    <div class="module__bg-image__img --tablet" style="--src:url('sites/2/2023/06/yuh-bg-desktop-lightpurple-03.png')">
    </div>
    <div class="module__bg-image__img " style="--src:url('sites/2/2023/06/yuh-bg-mobile-lightpurple-05.png')">
    </div>
  </div>

  <div class="app-features  js-app-features" style="--progress: 0; --step: 1; --total: 3;">
    <div class="app-features__pin js-app-features__pin">
      <div class="app-features__screens js-app-features__screens">

        <div class="app-features__inner container js-app-features__header">
          <div class="app-features__top">
            <h2 class="h2 app-features__main-headline">{{words['save']?.['saving-with-yuh']?.[lang.slug]}}<br />
            </h2>
          </div>
        </div>

        <div class="app-features__screens__outer container">
          <div class="app-features__screens__inner js-app-features__screens__inner">
            <div class="app-features__screen js-app-features__screen">
              <div class="app-features__feature js-app-features__feature   " data-bg="">

                <div
                  class="app-features__feature__image app-features__feature__image--mobile--center app-features__feature__image--desktop--center">
                  <picture class="">
                    <source
                      data-srcset="https://www.yuh.com/en/wp-content/uploads/sites/2/2023/06/yuh-app-features--save-desktop-01-1.svg"
                      media="--tablet" />
                    <source
                      data-srcset="https://www.yuh.com/en/wp-content/uploads/sites/2/2023/06/yuh-app-features--save-mobile-01-1.svg"
                      media="--until-tablet" />
                    <img class="lazyload app-features__image" data-sizes="auto" data-srcset="" style="" />
                  </picture>
                </div>
                <div class="app-features__feature__content">
                  <h3 class="h3 app-features__headline" [innerHTML]="words['save']?.['grow-title']?.[lang.slug]"><br />
                  </h3>
                  <p class="app-features__description copy-md">
                    {{words['save']?.['grow-text']?.[lang.slug]}}
                    <br />
                  </p>
                  <a (click)="pLoginActive = true"
                    class="button button--style--link button--size--default app-features__button">
                    <div class="button__inner">
                      <div class="icon button__icon button__icon--right"><svg xmlns="http://www.w3.org/2000/svg"
                          width="24" height="24" viewBox="0 0 24 24">
                          <path
                            d="M18 12a1 1 0 0 0-1 1v6a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1h6a1 1 0 0 0 0-2H5a3 3 0 0 0-3 3v11a3 3 0 0 0 3 3h11a3 3 0 0 0 3-3v-6a1 1 0 0 0-1-1Zm3.923-9.382a1 1 0 0 0-.541-.541A1.022 1.022 0 0 0 21 2h-6a1 1 0 0 0 0 2h3.586l-9.293 9.293a1 1 0 1 0 1.389 1.439l.025-.025L20 5.414V9a1 1 0 0 0 2 0V3a.984.984 0 0 0-.077-.382Z"
                            fill="#151a21" />
                        </svg></div> <span class="button__label">{{words['save']?.['sing-in']?.[lang.slug]}}</span>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>


<div class="module module--feature_small module--space-small module--bg-image" data-name="feature_small" id="">
  <div class="module__bg-image module__bg-image --position-buttom">
    <div class="module__bg-image__img " style="--src:url('sites/2/2023/06/yuh-bg-mobile-lightpurple-03.png')">
    </div>
  </div>
  <div class="feature-small feature-small--light_purple">
    <div class="container feature-small__inner">
      <div class="feature-small__features">
        <div class="feature-small__features__feature">
          <div class="feature-small__features__feature__inner">
            <h4 class="feature-small__features__feature__inner__title">{{words['save']?.['fs-title1']?.[lang.slug]}}</h4>
            <div class="feature-small__features__feature__inner__description copy-md color-grey">
              {{words['save']?.['fs-text1']?.[lang.slug]}}<br />
            </div>
          </div>
        </div>
        <div class="feature-small__features__feature">
          <div class="feature-small__features__feature__inner">
            <h4 class="feature-small__features__feature__inner__title">{{words['save']?.['fs-title2']?.[lang.slug]}}</h4>
            <div class="feature-small__features__feature__inner__description copy-md color-grey">
              {{words['save']?.['fs-text2']?.[lang.slug]}}<br />
            </div>
          </div>
        </div>
        <div class="feature-small__features__feature">
          <div class="feature-small__features__feature__inner">
            <h4 class="feature-small__features__feature__inner__title">{{words['save']?.['fs-title3']?.[lang.slug]}}</h4>
            <div class="feature-small__features__feature__inner__description copy-md color-grey">
              {{words['save']?.['fs-text3']?.[lang.slug]}}<br />
            </div>
          </div>
        </div>
        <div class="feature-small__features__feature">
          <div class="feature-small__features__feature__inner">
            <h4 class="feature-small__features__feature__inner__title">{{words['save']?.['fs-title4']?.[lang.slug]}}</h4>
            <div class="feature-small__features__feature__inner__description copy-md color-grey">
              {{words['save']?.['fs-text4']?.[lang.slug]}}<br />
            </div>
          </div>
        </div>
      </div>
    </div>
    <a (click)="pRegisterActive = true"
      class="button button--style--primary button--size--default feature-small__cta"
      data-ga4-trigger-params='{"event":"create_free_account","link_to":"#app-download","placement":"advantages_teaser"}'>
      <div class="button__inner">
        <span class="button__label">{{words['save']?.['create-acc']?.[lang.slug]}}</span>
      </div>
    </a>
  </div>
</div>
<div
  class="module module--image-text js-content-navigation__section module--space-top-small module--space-bottom-medium module--bg-image"
  data-section-index="1" data-name="image-text" id="Fees">
  <div class="module__bg-image module__bg-image">
    <div class="module__bg-image__img --tablet" style="--src:url('sites/2/2023/06/yuh-bg-desktop-lightpurple-04.png')">
    </div>
    <div class="module__bg-image__img " style="--src:url('sites/2/2023/06/yuh-bg-mobile-lightpurple-03.png')">
    </div>
  </div>

  <div class="image-text container ">
    <picture class="image-text__image">
      <img class="lazyload " data-sizes="auto"
        data-srcset="https://www.yuh.com/en/wp-content/uploads/sites/2/2023/05/yuhperson-dp-4b.svg 280w"
        alt="Illustrations" style="" />
    </picture>
    <div class="image-text__body image-text__body__center">
      <h2 class="h2 image-text__headline weight-extrabold" [innerHTML]="words['save']?.['calc-title']?.[lang.slug]"></h2>
      <the-calculator></the-calculator>
    </div>

  </div>
</div>

<div class="module module--crosslinks" data-name="crosslinks" id="" style="margin: 20rem 0">
  <div class="crosslinks ">
    <div class="container">

    </div>
    <div class="container crosslinks__links">
      <div class="download__bg">
        <div class="download__bg__inner">
          <picture class="">
            <source data-srcset="https://www.yuh.com/en/wp-content/themes/yuh/assets/images/404/bg-group-sm.svg"
              media="(max-width: 759px)" />
            <source data-srcset="https://www.yuh.com/en/wp-content/themes/yuh/assets/images/404/bg-group.svg"
              media="(min-width: 760px)" />
            <img class="lazyload download__bg__landscape" data-sizes="auto" data-srcset="" alt="Illustration"
              style="" />
          </picture>
          <picture class="">
            <img class="lazyload download__bg__item download__bg__item--left" data-sizes="auto"
              data-srcset="https://www.yuh.com/en/wp-content/themes/yuh/assets/images/404/illustration1.svg"
              alt="Illustration" style="" />
          </picture>
          <picture class="">
            <img class="lazyload download__bg__item download__bg__item--center" data-sizes="auto"
              data-srcset="https://www.yuh.com/en/wp-content/themes/yuh/assets/images/404/illustration3.svg"
              alt="Illustration" style="" />
          </picture>
          <picture class="">
            <img class="lazyload download__bg__item download__bg__item--right" data-sizes="auto"
              data-srcset="https://www.yuh.com/en/wp-content/themes/yuh/assets/images/404/illustration2.svg"
              alt="Illustration" style="" />
          </picture>
        </div>
      </div>
    </div>
  </div>
</div>
