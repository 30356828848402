import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { DirectivesModule } from "../directives/directives.module";
import { PipesModule } from "../pipes/pipes.module";
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { FooterComponent } from "./footer/footer.component";
import { HeaderComponent } from "./header/header.component";
import { MMAccountComponent } from "./mobile-menus/mm-account/mm-account.component";
import { MMMainComponent } from "./mobile-menus/mm-main/mm-main.component";
import { PanelLogoutComponent } from "./panels/logout/panel-logout.component";
import { PanelNotificationsComponent } from "./panels/notifications/panel-notifications.component";
import { PopupLoginComponent } from "./popups/login/popup-login.component";
import { PopupLogoutComponent } from "./popups/logout/popup-logout.component";
import { PopupReceiveBankComponent } from "./popups/receive/bank/popup-receive-bank.component";
import { PopupReceiveCryptoComponent } from "./popups/receive/crypto/popup-receive-crypto.component";
import { PopupRegisterComponent } from "./popups/register/popup-register.component";
import { PopupRegisteredComponent } from "./popups/registered/popup-registered.component";
import { PopupSendBankComponent } from "./popups/send/bank/popup-send-bank.component";
import { PopupSendCryptoComponent } from "./popups/send/crypto/popup-send-crypto.component";
import { PopupCreditComponent } from "./popups/credit/popup-credit.component";
import { CardComponent } from "./card/card.component";
import { PeriodComponent } from "./period/period.component";
import { PopupCreditedComponent } from "./popups/credited/popup-credited.component";
import { CalculatorComponent } from "./calculator/calculator.component";
import { SliderComponent } from "./slider/slider.component";
import { LangDropdown } from "./lang-dropdown/lang-dropdown.component";
import { PopupCreditOrderComponent } from "./popups/credit-order/popup-credit-order.component";

@NgModule({
    imports: [
        RouterModule,
        CommonModule,
        FormsModule,    
        PipesModule,    
        DirectivesModule,     
        NgxSliderModule
    ],
    declarations: [
        FooterComponent,
        HeaderComponent,
        PopupRegisterComponent,
        PopupRegisteredComponent,
        PopupLoginComponent,
        PopupLogoutComponent,
        PopupSendBankComponent,
        PopupSendCryptoComponent,
        PopupReceiveBankComponent,
        PopupReceiveCryptoComponent,
        PopupCreditComponent,
        PopupCreditedComponent,
        PopupCreditOrderComponent,
        PanelNotificationsComponent,
        PanelLogoutComponent,
        MMMainComponent,
        MMAccountComponent,
		    CardComponent,
        PeriodComponent,
        CalculatorComponent,
        SliderComponent,
        LangDropdown,
    ],
    exports: [
        FooterComponent,
        HeaderComponent,
        PopupRegisterComponent,
        PopupRegisteredComponent,
        PopupLoginComponent,
        PopupLogoutComponent,
        PopupSendBankComponent,
        PopupSendCryptoComponent,
        PopupReceiveBankComponent,
        PopupReceiveCryptoComponent,
        PopupCreditComponent,
        PopupCreditedComponent,
        PopupCreditOrderComponent,
        PanelNotificationsComponent,
        PanelLogoutComponent,
        MMMainComponent,
        MMAccountComponent,
        CardComponent,
        PeriodComponent,
        CalculatorComponent,
        SliderComponent,
        LangDropdown,
    ],      
    providers: [],
})
export class ComponentsModule {}
