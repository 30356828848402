<div class="image_video_carousel image_video_carousel--style-- image_video_carousel--size--"
  style="--image_video_carousel-speed: 600ms; --image_video_carousel-autoplay-delay: 5000ms;">
  <div class="container">
    <div class="image_video_carousel__inner">
      <div class="image_video_carousel__main">
        <div class="image_video_carousel__slider " style="--aspect-ratio: 1/1"
          data-component='{ "component": "slider" }'
          data-slider-settings='{"slidesPerView":1,"spaceBetween":16,"navigation":true,"grabCursor":true,"navigationWrapper":true,"controlling":"image_video_carousel-64971530e5c52-main","thumbsControl":false,"autoplay":false,"speed":600,"autoHeight":false,"slideToClickedHiddenSlide":true,"breakpoints":{"tablet":{"slidesPerView":2},"tablet-landscape":{"slidesPerView":3}}}'>
          <div class="image_video_carousel__item">
            <div class="image_video_carousel__item__visual">
              <picture class="">
                <img class="lazyload image_video_carousel__item__visual__asset video--controls" data-sizes="auto"
                  data-srcset="/assets/images/a1.jpeg"
                  alt="" style="--aspect-ratio: 1/1;" />
              </picture>
            </div>
            <div class="image_video_carousel__item__content">
            </div>
          </div>
          <div class="image_video_carousel__item">
            <div class="image_video_carousel__item__visual">
              <picture class="">
                <img class="lazyload image_video_carousel__item__visual__asset video--controls" data-sizes="auto"
                  data-srcset="/assets/images/a2.jpeg"
                  alt="" style="--aspect-ratio: 1/1;" />
              </picture>
            </div>
            <div class="image_video_carousel__item__content">
            </div>
          </div>
          <div class="image_video_carousel__item">
            <div class="image_video_carousel__item__visual">
              <picture class="">
                <img class="lazyload image_video_carousel__item__visual__asset video--controls" data-sizes="auto"
                  data-srcset="/assets/images/a3.jpeg"
                  alt="" style="--aspect-ratio: 1/1;" />
              </picture>
            </div>
            <div class="image_video_carousel__item__content">
            </div>
          </div>
          <div class="image_video_carousel__item">
            <div class="image_video_carousel__item__visual">
              <picture class="">
                <img class="lazyload image_video_carousel__item__visual__asset video--controls" data-sizes="auto"
                  data-srcset="/assets/images/a4.jpeg"
                  alt="" style="--aspect-ratio: 1/1;" />
              </picture>
            </div>
            <div class="image_video_carousel__item__content">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
