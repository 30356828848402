    <div class="header js-header container" data-component='{ "component": "header" }'>
      <div class="header__bar">
        <a  routerLink="/{{lang.slug}}" class="header__logo">
          <img class="lazyload lock-orange js-lock-orange security__inner__card__illustrations__illustration-img"
            data-sizes="rite" data-srcset="wp-content/themes/yuh/assets/images/hero-general/logo.png" alt="RDD Bank"
            style="" />
        </a>

        <button class="header__burger js-header__burger" role="button"></button>
        <nav class="header__nav js-header__nav">
          <ul class="header__nav__items js-header__nav__items">
            <li class="header__nav__item js-header__nav__item is-subnav">
              <a class="header__nav__link js-header__nav__link" [class.is-selected]="false"
                data-ga4-trigger-params='{"event":"navigation","link_to":"en\/"}'>
                {{words['header']?.['app']?.[lang.slug]}}<div class="icon header__nav__link__arrow"><svg xmlns="http://www.w3.org/2000/svg" width="24"
                    height="24" viewBox="0 0 24 24">
                    <path
                      d="M19.5 9a1.5 1.5 0 0 0-2.561-1.061L12 12.879l-4.939-4.94a1.5 1.5 0 0 0-2.122 2.122l6 6a1.5 1.5 0 0 0 2.122 0l6-6A1.5 1.5 0 0 0 19.5 9Z"
                      fill="currentColor" />
                  </svg></div> </a>
              <div class="header__nav__sub">
                <ul class="header__nav__subitems js-header__nav__subitems">
                  <li class="header__nav__subitem">
                    <a class="header__nav__sublink" href="app/how-it-works/index.html"
                      data-ga4-trigger-params='{"event":"navigation","link_to":"en\/app\/how-it-works\/"}'>

                    </a>

                  </li>
                  <li class="header__nav__subitem">
                    <a class="header__nav__sublink" routerLink="/{{lang.slug}}/pay" (click)="closeMobileMenu()"
                      data-ga4-trigger-params='{"event":"navigation","link_to":"en\/app\/pay\/"}'>
                      <span class="header__nav__sublink__image bg-color-pink">
                        <picture class="">
                          <img class="lazyload " data-sizes="auto"
                            data-srcset="https://www.yuh.com/en/wp-content/uploads/sites/2/2023/05/yuh-card-02.svg 500w,https://www.yuh.com/en/wp-content/uploads/sites/2/2023/05/yuh-card-02.svg 1000w,https://www.yuh.com/en/wp-content/uploads/sites/2/2023/05/yuh-card-02.svg 1500w"
                            alt="yuh card" style="" />
                        </picture>
                      </span>
                      {{words['header']?.['menu-pay']?.[lang.slug]}}</a>
                  </li>
                  <li class="header__nav__subitem">
                    <a class="header__nav__sublink" routerLink="/{{lang.slug}}/save" (click)="closeMobileMenu()"
                      data-ga4-trigger-params='{"event":"navigation","link_to":"en\/app\/save\/"}'>
                      <span class="header__nav__sublink__image bg-color-light-purple">
                        <picture class="">
                          <img class="lazyload " data-sizes="auto"
                            data-srcset="wp-content/themes/yuh/assets/images/hero-general/save.png" alt="Save Icon"
                            style="" />
                        </picture>
                      </span>
                      {{words['header']?.['menu-save']?.[lang.slug]}} </a>
                  </li>
                  <li class="header__nav__subitem">
                    <a class="header__nav__sublink" routerLink="/{{lang.slug}}/invest" (click)="closeMobileMenu()"
                      data-ga4-trigger-params='{"event":"navigation","link_to":"en\/app\/invest\/"}'>
                      <span class="header__nav__sublink__image bg-color-dark-purple">
                        <picture class="">
                          <img class="lazyload " data-sizes="auto"
                            data-srcset="https://www.yuh.com/en/wp-content/uploads/sites/2/fly-images/188/invest-40x40.png 500w,https://www.yuh.com/en/wp-content/uploads/sites/2/fly-images/188/invest-40x40.png 1000w,https://www.yuh.com/en/wp-content/uploads/sites/2/fly-images/188/invest-40x40.png 1500w"
                            alt="Invest Icon" style="" />
                        </picture>
                      </span>
                      {{words['header']?.['menu-invest']?.[lang.slug]}}</a>
                  </li>
                  <li class="header__nav__subitem">
                    <a class="header__nav__sublink" (click)="pCreditOrderActive = true"
                      data-ga4-trigger-params='{"event":"navigation","link_to":"en\/app\/swissqoins\/"}'>
                      <span class="header__nav__sublink__image bg-color-black">
                        <picture class="">
                          <img class="lazyload " data-sizes="auto"
                            data-srcset="https://www.yuh.com/en/wp-content/uploads/sites/2/fly-images/206/swissqoin-40x40.png 500w,https://www.yuh.com/en/wp-content/uploads/sites/2/fly-images/206/swissqoin-40x40.png 1000w,https://www.yuh.com/en/wp-content/uploads/sites/2/fly-images/206/swissqoin-40x40.png 1500w"
                            alt="Swissqoin Logo" style="" />
                        </picture>
                      </span>
                      {{words['header']?.['menu-credits']?.[lang.slug]}}</a>
                  </li>
                </ul>

              </div>

            </li>
            <li class="header__nav__item js-header__nav__item ">
              <a class="header__nav__link js-header__nav__link " routerLink="/{{lang.slug}}/about"
                data-ga4-trigger-params='{"event":"navigation","link_to":"en\/about-yuh\/"}'>
                {{words['header']?.['about']?.[lang.slug]}}</a>

            </li>
            <li class="header__nav__item js-header__nav__item">
              <a class="header__nav__link js-header__nav__link" (click)="onMenuClick('footer')">
                {{words['header']?.['contact']?.[lang.slug]}}
              </a>
            </li>
            <ng-container *ngIf="authenticated">
              <li class="header__nav__item js-header__nav__item">
                <a routerLink="/{{lang.slug}}/account" class="header__nav__link js-header__nav__link" (click)="closeMobileMenu()">
                  {{words['header']?.['account']?.[lang.slug]}}
                </a>
              </li>
            </ng-container>
          </ul>

          <div class="header__button__mobile">
          </div>
            <ng-container *ngIf="authenticated">
              <a
                routerLink="/{{lang.slug}}/account/logout"
                (click)="closeMobileMenu()"
                class="button button--style--primary button--size--large logout-button"
                data-ga4-trigger-params='{"event":"get_started"}'>
                <div class="button__inner">
                  <span class="button__label">{{words['logout']?.['logout']?.[lang.slug]}}</span>
                </div>
              </a>
            </ng-container>
        </nav>
        <nav class="header__nav-lg header__nav__item is-subnav js-header__nav__item js-header__nav-lg">
          <div class="header__nav__link header__nav__link--lg js-header__nav__link">
            <div class="icon header__nav-lg__flag">
              <img [src]="getLangImg(lang)">
            </div>
            <div class="icon header__nav__link__arrow"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                viewBox="0 0 24 24">
                <path
                  d="M19.5 9a1.5 1.5 0 0 0-2.561-1.061L12 12.879l-4.939-4.94a1.5 1.5 0 0 0-2.122 2.122l6 6a1.5 1.5 0 0 0 2.122 0l6-6A1.5 1.5 0 0 0 19.5 9Z"
                  fill="currentColor" />
              </svg></div>
          </div>

          <div class="header__nav__sub">
            <ul class="header__nav__subitems js-header__nav__subitems">
              <li class="header__nav__subitem" *ngFor="let l of langs">
                <a class="header__nav__sublink" [routerLink]="getLangLink(l)">
                  <span class="header__nav__sublink__flag">
                    <div class="icon header__nav-lg__link__flag">
                      <img [src]="getLangImg(l)">
                    </div>
                  </span>
                  {{l.fulltitle}}</a>
              </li>
            </ul>
          </div>
        </nav>
        <div class="header__button">
          <div class="header__button__desktop">
            <div class="icons-container" *ngIf="authenticated">
                <div class="ha-links">
                    <div routerLink="/{{lang.slug}}/account" class="smallbtn account" [class.active]="url[2] === 'account' && !url[3]"></div>
                </div>
                <div class="ha-notifications">
                    <div class="smallbtn notifications" (click)="pNotificationsActive = true" [class.active]="pNotificationsActive"></div>
                    <panel-notifications [(active)]="pNotificationsActive"></panel-notifications>
                </div>
                <div class="ha-links">
                    <div (click)="pLogoutActive = true" class="smallbtn logout" [class.active-red]="pLogoutActive"></div>
                    <panel-logout [(active)]="pLogoutActive"></panel-logout>
                </div>
            </div>
            <ng-container *ngIf="!authenticated">
              <a
                (click)="pLoginActive = true"
                class="button button--style--primary button--size--large"
                data-ga4-trigger-params='{"event":"get_started"}'>
                <div class="button__inner">
                  <span class="button__label">{{words['header']?.['login']?.[lang.slug]}}</span>
                </div>
              </a>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
