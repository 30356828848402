<div class="module module--image-text module--space-top-small module--space-bottom-medium module--bg-image"
  data-name="image-text" id="">
  <div class="module__bg-image module__bg-image">
    <div class="module__bg-image__img --tablet"
      style="--src:url('')"></div>
  </div>

  <div class="image-text container image-text--image-right">
    <picture class="image-text__image">
      <img class="lazyload " data-sizes="auto"
        data-srcset="https://www.yuh.com/en/wp-content/uploads/sites/2/2022/12/illustration-system.svg 280w"
        alt="Illustration Person Sitting" style="" />
    </picture>
    <div class="image-text__body ">
      <h6 class="h6 image-text__title">{{words['home']?.['rdd-learn']?.[lang.slug]}}</h6>
      <h1 class="h2 image-text__headline weight-extrabold" [innerHTML]="words['home']?.['rdd-learn-text']?.[lang.slug]"><br /></h1>
    </div>
  </div>
</div>
